/* Issue fixed for github issue #23,issue #24, issue #29, issue #58, issue #59,issue #83,issue #82,issue #81,issue #74,issue #140,issue #218,issue #316*/
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import NotificationCard from "components/notificationCards/NotificationCards";
import { Grid, InputBase } from "@material-ui/core";
import { Colors } from "common/Colors";
import AssetStatusCard from "components/AssetStatusCard/AssetStatusCard";
import AssetReadinessCard from "components/AssetReadinessCard/AssetReadinessCard";
import SingleAssetCard from "components/SingleAssetCard/SingleAssetCard";
import AssetMap from "components/AssetMap/AssetMap";
import { dashboardAPI } from "services/dashboardAPI";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { AllNotifications, Asset, Group, PathwayProps, GroupsList } from "./types";
import LoadSpinner from "common/LoadSpinner";
import { LinearIndeterminate } from "common/LinearLoading";
import { addPath } from "./RealTimeStatusPageSlice";
import RealTimeNavBar from "./RealTimeNavBar";
import searchIcon from "assets/bt-assets/header_search_w.svg";
import AccountAPI from "services/accountsAPI";
import AssetDrilldown from "features/asset-drilldown/AssetDrilldown";
import { useAccessLevelAndRoles, useIsMounted } from "utils/reactHooks";
import { addSeconds } from "date-fns";
import { DASHBOARD_TIMEOUT_SECONDS } from "config";
import { parsePathwayForGroup, parsePathwayForAsset } from "utils/helpers";

const RealTimeStatusDashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  const allTenantsRef = useRef<any[]>([]);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const timeoutLimit = useRef<Date>(addSeconds(new Date(), 60)); // high initial limit, will be overwritten below
  const {
    isBasintrakEngineer,
    isBasintrakSuperAdmin,
    isTenant
  } = useAccessLevelAndRoles();

  const [dropdownSelection, setDropdownSelection] = useState("critical");
  const [searchInput, setSearchInput] = useState("");
  const [accountNotifications, setAccountNotifications] = useState<
    AllNotifications
  >({
    critical_total: 0,
    notification_total: 0,
    warning_total: 0
  });
  const [accountGroups, setAccountGroups] = useState<Group[]>([]);
  // Changed name to assetObject instead of assets for issue #316
  const [assetObject, setAssetObject] = useState<Asset[]>([]);
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(true);

  const { accounts } = useSelector(
    (state: RootState) => state.AppSlice.userInformation
  );
  const { accountId } = useSelector(
    (state: RootState) => state.AppSlice.selectedAccount
  );

  const { pathway } = useSelector(
    (state: RootState) => state.RealTimeStatusPageSlice
  );

  const persistedGroupRef = useRef<PathwayProps | null>(
    parsePathwayForGroup(pathway)
  );

  const assetData = useMemo(() => parsePathwayForAsset(pathway), [pathway]);
  const persistedAssetRef = useRef<PathwayProps | null>(
    parsePathwayForAsset(pathway)
  );

  const addPathToSlice = useCallback(
    (groupName: string, assetGroupId: string) => {
      const newPath = { name: groupName, id: assetGroupId };
      dispatch(addPath(newPath));
    },
    [dispatch]
  );

  const returnToInitialDashboard = useCallback(
    async (accountId: string, successCallback?: () => void) => {
      // ! gotta route to either admin or general user
      try {
        // console.log("STATUS DASHBOARD - GENERAL", accounts);
        const currentAccount = accounts.filter(
          (account: any) => account.tenant_id === accountId
        )[0];

        if (currentAccount) {
          const assetGroupIds = currentAccount.groups.map((group: any) => ({
            asset_group_id: group.asset_group_id
          }));
          setLoading(true);
          //Changed the response type of API call as done in the backend for issue #74
          const {
            groups = [],
            all_notifications = {} as AllNotifications,
            assets = []
          } = await dashboardAPI.getDashboardId(accountId, assetGroupIds);
          //Removed the call to dashboardhandler function as we are already getting the data in above API call for issue #76
          setLoading(false);

          const groupsList: GroupsList[] = groups.map(group => ({
            asset_group_id: group.asset_group_id
          }));

          //Calling the handlegroup function as dashboardhandler function is removed for issue #74
          handleGroupsAll(
            groups[0].group_name,
            groups[0].asset_group_id,
            groupsList,
            true,
            successCallback
          );

        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        alert("Error returning to initial dashboard");
      }
    },
    [accounts]
  );

  const handleGroup = useCallback(
    async (
      groupName: string,
      assetGroupId: string,
      returningToGroup: boolean = false,
      successCallback?: () => void
    ) => {
      try {
        setLoading(true);
        //Changed the response type of API call as done in the backend for issue #74
        const {
          groups = [],
          all_notifications = {} as AllNotifications,
          assets = []
        } = await dashboardAPI.openGroup(assetGroupId);
        if (isMounted.current) {
          //Added function to sort the groups list for issue #218
          groups.sort((a: any, b: any) => {
            if (a.group_name < b.group_name) return -1;
            return a.group_name > b.group_name ? 1 : 0;
          });
          //Added function to sort the assets list for issue #218
          assets.sort((a: any, b: any) => {
            if (a.asset_name < b.asset_name) return -1;
            return a.asset_name > b.asset_name ? 1 : 0;
          });
          // Setting the data since getDashboardDataHandler function is removed for issue #74
          setAccountGroups(groups);
          setAccountNotifications(all_notifications);
          setAssetObject(assets);
          successCallback && successCallback();
          setLoading(false);
          // Adding path to graybar since getDashboardDataHandler function is removed for issue #74
          if (!returningToGroup) {
            addPathToSlice(groupName, assetGroupId);
          }
        }
      } catch (error) {
        alert("Open group API error");
        console.log(error);
        setLoading(false);
      }
    },
    [addPathToSlice, isMounted]
  );

  const handleGroupsAll = useCallback(
    async (
      groupName: string,
      assetGroupId: string,
      groupsAll: GroupsList[],
      returningToGroup: boolean = false,
      successCallback?: () => void
    ) => {
      try {
        setLoading(true);
        //Changed the response type of API call as done in the backend for issue #74
        const {
          groups = [],
          all_notifications = {} as AllNotifications,
          assets = []
        } = await dashboardAPI.openGroupAll(groupsAll);
        if (isMounted.current) {
          //Added function to sort the groups list for issue #218
          groups.sort((a: any, b: any) => {
            if (a.group_name < b.group_name) return -1;
            return a.group_name > b.group_name ? 1 : 0;
          });
          //Added function to sort the assets list for issue #218
          assets.sort((a: any, b: any) => {
            if (a.asset_name < b.asset_name) return -1;
            return a.asset_name > b.asset_name ? 1 : 0;
          });
          // Setting the data since getDashboardDataHandler function is removed for issue #74
          setAccountGroups(groups);
          setAccountNotifications(all_notifications);
          setAssetObject(assets);
          successCallback && successCallback();
          setLoading(false);
          // Adding path to graybar since getDashboardDataHandler function is removed for issue #74
          if (!returningToGroup) {
            addPathToSlice(groupName, assetGroupId);
          }
        }
      } catch (error) {
        alert("Open group API error");
        console.log(error);
        setLoading(false);
      }
    },
    [addPathToSlice, isMounted]
  );

  const returnToGroup = useCallback(
    (groupName: string, assetGroupId: string, successCallback?: () => void) => {
      handleGroup(groupName, assetGroupId, true, successCallback);
    },
    [handleGroup]
  );

  const handleAsset = useCallback(
    async (assetName: string, assetId: string, complexAsset) => {
      setLoading(true);
      dispatch(
        addPath({
          name: assetName,
          id: assetId,
          isAsset: true,
          complexAsset
        })
      );
      setLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    persistedGroupRef.current = parsePathwayForGroup(pathway);
    persistedAssetRef.current = parsePathwayForAsset(pathway);
  }, [pathway]);

  useEffect(() => {
    // reset this page state whenever user switches accounts from sidebar
    // setInitializing(true); // allows rare edge case crash from leaflet/map being unmounted/remounted from spin loader
    setAssetObject([]);
    setAccountGroups([]);
  }, [accountId]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (
      !isMounted.current ||
      accountId === "" ||
      !Array.isArray(accounts) ||
      !accounts.length
    ) {
      return; // prevent multiple instances of this useEffect firing with uninitialized or invalid values
    }

    if (persistedGroupRef.current) {
      // case: user refreshed and was previously viewing a group, let's resume that group view/data
      const { name, id } = persistedGroupRef.current;
      handleGroup(name, id, true, () => {
        setInitializing(false);
        setLoading(false);
      });
      return;
    }

    if (persistedAssetRef.current) {
      // case: user refreshed and was previously viewing an asset, let's resume that asset view/data
      setInitializing(false);
      setLoading(false);
      return;
    }

    const initializeDashboardHandler = async (
      tenantId: string,
      groups1: string[]
    ) => {
      try {
        const formattedAssetGrps = groups1.map(id => ({ asset_group_id: id }));
        //Changed the response type of API call as done in the backend for issue #74
        const {
          groups = [],
          all_notifications = {} as AllNotifications,
          assets = []
        } = await dashboardAPI.getDashboardId(tenantId, formattedAssetGrps);

        //Removed the call to dashboardhandler function as we are already getting the data in above API call for issue #76
        setLoading(false);
        const groupsList: GroupsList[] = groups.map(group => ({
          asset_group_id: group.asset_group_id
        }));

        //Calling the handlegroup function as dashboardhandler function is removed for issue #74
        handleGroupsAll(
          groups[0].group_name,
          groups[0].asset_group_id,
          groupsList,
          true,
          () => {
            setInitializing(false);
          }
        );
      } catch (err) {
        // alert("Error getting dashboard ID");
        setLoading(false);
      }
    };

    (async () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setLoading(true);
      if (isBasintrakSuperAdmin || isBasintrakEngineer) {
        try {
          // ! this one will catch all tenant groups and display it
          // console.log("STATUS DASHBOARD - ADMIN");
          let allTenants = allTenantsRef.current;
          if (!allTenants.length) {
            allTenantsRef.current = (await AccountAPI.getAllTenants()) as any[];
            allTenants = allTenantsRef.current;
          }
          const ownerAccountInfo = allTenants.find(
            (tenant: any) => tenant.tenant_id === accountId
          ); // || {};
          if (ownerAccountInfo) {
            const masterGroupId = ownerAccountInfo.master_group_id as string; // "3267debc-5692-4c88-84ee-cbcc405ef30f";
            // kick off a fresh dashboard request process
            initializeDashboardHandler(accountId, [masterGroupId]);
          } else {
            setLoading(false);
            alert("Error finding super account ID");
          }
        } catch (error) {
          console.log(error);
          alert("Error preparing initial dashboard request");
          setLoading(false);
        }
      } else if (isTenant) {
        try {
          const currentAccount = accounts.filter(
            (account: any) => account.tenant_id === accountId
          )[0];

          if (currentAccount) {
            const assetGroupIds = currentAccount.groups.map(
              (group: any) => group.asset_group_id
            ) as string[];
            // kick off a fresh dashboard request process
            initializeDashboardHandler(accountId, assetGroupIds);
          } else {
            alert("Error finding general account ID");
          }
        } catch (error) {
          setLoading(false);
          alert("Error preparing initial dashboard request");
          console.log(error);
        }
      } else {
        setLoading(false);
        alert("Edge case: not an ad/eng/gen access level");
      }
    })();
  }, [
    accounts,
    accountId,
    isBasintrakSuperAdmin,
    isBasintrakEngineer,
    isTenant,
    handleGroup,
    isMounted
  ]);

  if (initializing) {
    return <LoadSpinner size="90vh" />;
  }

  return (
    <Grid
      container
      className={classes.root}
      style={{
        pointerEvents: loading ? "none" : "all"
      }}
    >
      <RealTimeNavBar
        returnToInitialDashboard={returnToInitialDashboard}
        setAccountNotifications={setAccountNotifications}
        setAccountGroups={setAccountGroups}
        returnToGroup={returnToGroup}
        setInitialLoading={setLoading}
        initialLoading={loading}
      />
      {loading && <LinearIndeterminate />}
      {assetData ? ( // ! original value, change back
        // {true ? ( // ! DEVELOPMENT PURPOSE ONLY, change back to assetData
        <AssetDrilldown
          // ! CHANGE BACK
          assetId={assetData.id}
          complexAsset={assetData.complexAsset}
        // assetId={"8465060b-a91b-4214-837d-f39c5e3639f2"}
        // complexAsset={true}
        />
      ) : (
        <>
          {/* Changing the grid layout and using simple div instead of grid for issue #140 */}
          <div className={classes.notificationsContainer}>
            {/* Adding div with styles for first notifications tile for issue #140 */}
            <div style={{ gridArea: "one" }}>
              <NotificationCard
                amount={accountNotifications.critical_total || 0}
                alertLevel={"critical"}
                assetLevel={false} //Adding assetLevel for showing it is on group level for issue #81
                tileNumber={1}
                timestamp={""}
              />
            </div>
            {/* Adding div with styles for second notifications tile for issue #140 */}
            <div style={{ gridArea: "two" }}>
              <NotificationCard
                amount={accountNotifications.warning_total || 0}
                alertLevel={"warning"}
                assetLevel={false} //Adding assetLevel for showing it is on group level for issue #81
                tileNumber={2}
                timestamp={""}
              />
            </div>
            {/* Adding div with styles for third notifications tile for issue #140 */}
            <div style={{ gridArea: "three" }}>
              <NotificationCard
                amount={accountNotifications.notification_total || 0}
                alertLevel={"notification"}
                assetLevel={false} //Adding assetLevel for showing it is on group level for issue #81
                tileNumber={3}
                timestamp={""}
              />
            </div>
            {/* added css class maingrid for styling the grid in mobile view for responsive UI issue #24 */}
            {/* Added div with css instead of grid component for issue #140 */}
            <div className={classes.notificationCardContainer}>
              {/* // ! sort and search functionality hidden for later phase */}
              {/* <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <span className={classes.sortByText}>Sort by:</span>
                <select
                  style={{ width: "auto" }}
                  className={classes.sortSelect}
                  onChange={handleChange}
                  value={dropdownSelection}
                >
                  <option value={"critical"}>Critical First</option>
                  <option value={"connectivity"}>Connectivity issues</option>
                  <option value={"highestAssets"}>
                    Highest # of assets first
                  </option>
                  <option value={"lowestAssets"}>
                    Lowest # of assets first
                  </option>
                </select>
                <div className={classes.search}>
                  <InputBase
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    value={searchInput}
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      setSearchInput(e.target.value);
                    }}
                    inputProps={{ "aria-label": "search" }}
                    placeholder="Search for group or asset"
                  />
                  <div className={classes.searchbutton}>
                    <img src={searchIcon} alt="" />
                  </div>
                </div>
              </div> */}
              {/* removing the "styles" tab and adding css class assetlayout for responsive UI issue #24 */}
              {/* Added props index for styling odd even tiles for issue #82 */}
              {accountGroups.map((group: any, index: number) => {
                return (
                  <AssetStatusCard
                    group={group}
                    handleGroup={handleGroup}
                    key={group.asset_group_id}
                    index={index} //Added index for checking for issue #82
                  />
                );
              })}
              {assetObject.map((asset: any, index: number) => {
                if (asset.complex_asset) {
                  return (
                    <AssetReadinessCard
                      asset={asset}
                      handleAsset={handleAsset}
                      key={asset.asset_id}
                      index={index} //Added index for checking for issue #82
                    />
                  );
                }
                return (
                  <SingleAssetCard
                    singleAsset={asset}
                    handleAsset={handleAsset}
                    key={asset.asset_id}
                    index={index} //Added index for checking for issue #82
                  />
                );
              })}
            </div>
            {/* Added div with css instead of grid component for map for issue #140 */}
            <div className={classes.mapCardContainer}>
              <AssetMap
                accountGroups={accountGroups}
                assets={assetObject}
                singleAsset={false} //adding singleAsset to false so that this operates on group level issue #23
                handleGroup={handleGroup}
                handleAsset={handleAsset}
              />
            </div>
          </div>
          {/* added div and css class for footer in responsive UI issue #24 */}
          <div className={classes.footerDiv}>
            <p className={classes.footerStyle}>
              © 2024 Rulo LLC.
            </p>
          </div>
        </>
      )}
    </Grid>
  );
};
export default RealTimeStatusDashboard;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column"
    },
    margin: {
      margin: theme.spacing(1)
    },
    sortByText: {
      marginBottom: "1em",
      fontWeight: "bold",
      height: "2.5em",
      padding: ".5rem .5rem .5rem .5rem"
    },
    select: {
      width: "25%",
      marginBottom: "1em",
      height: "2.5em",
      border: `1px solid ${Colors.LightGray}`,
      padding: ".5rem .5rem .5rem 1rem",
      color: theme.palette.primary.light,
      fontWeight: "bold",
      "& option": {
        color: theme.palette.primary.light,
        fontWeight: "bold"
      },

      //added media query for responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        color: "lightblue"
      }
    },
    sortSelect: {
      marginBottom: "1em",
      height: "2.5em",
      border: `1px solid ${Colors.LightGray}`,
      padding: ".5rem .5rem .5rem 1rem",
      color: theme.palette.primary.light,
      fontWeight: "bold",
      "& option": {
        color: theme.palette.primary.light,
        fontWeight: "bold"
      }
    },
    searchInput: {
      width: "30%",
      marginLeft: "39%",
      marginBottom: "1em",
      height: "2.5em",
      border: `1px solid ${Colors.LightGray}`,
      padding: ".5rem .5rem .5rem 1rem",
      color: theme.palette.primary.light,
      fontWeight: "bold",
      "& option": {
        color: theme.palette.primary.light,
        fontWeight: "bold"
      }
    },
    textField: {
      width: "25ch"
    },
    sortByDropdown: { marginLeft: "2em" },
    notificationsContainer: {
      // Changing the css to build a grid layout for issue #140
      display: "grid",
      gridTemplateAreas: '"one two three" "four four five"', //adding grid template areas for tiles and items for issue #140
      gridTemplateColumns: "repeat(3,1fr)",
      columnGap: "1.5rem",
      marginTop: "1rem",

      [`@media (max-width: 800px)`]: {
        marginLeft: "1.25rem",
        marginRight: "1.25rem"
      },

      // added media query for all notification tiles to stack on top of one another till 1300 resolution for issue #140
      [`@media (max-width: 1300px)`]: {
        gridTemplateColumns: "repeat(1,1fr)",
        gridTemplateAreas: '"one" "two" "three" "four" "five"'
      }
    },
    //Added new css class for groups and asset tiles to stack on top of one another till 1300 resolution for issue #140
    notificationCardContainer: {
      gridArea: "four",
      marginTop: "2em",
      display: "grid",
      gridTemplateColumns: "repeat(2,minmax(0,1fr))",
      columnGap: "1.5rem",
      rowGap: "1rem",
      // Adding media query for groups and assets tiles to stack on top of one another for issue #140
      [`@media (max-width: 1300px)`]: {
        gridTemplateColumns: "repeat(1,1fr)"
      }
    },
    mapCardContainer: {
      gridArea: "five", //added grid area for map to align with 3rd notification tile for issue #140
      marginTop: "2em",
      minHeight: "35em",
      display: "flex",
      flexDirection: "row",

      //adding media query for responsive UI issue #24 for displaying contents in grid view in mobile
      [`@media (max-width: 800px)`]: {
        display: "grid",
        minHeight: "35em", //for adjusting min height in mobile view for responsive UI issue #24
        maxHeight: "35em" //for adjusting max height in mobile view for responsive UI issue #24
      }
    },
    search: {
      display: "flex",
      flexDirection: "row",
      height: "2.5em",
      marginLeft: "auto"
    },
    inputRoot: {
      border: "1px solid #DDD",
      borderRadius: 0,
      width: "100%",
      backgroundColor: "#fff"
    },
    inputInput: {
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      width: "100%"
    },
    searchbutton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      cursor: "pointer",
      backgroundColor: `${theme.palette.primary.light}`,
      width: 45
    },

    //adding changes made for issue #24
    //css class for acess groups view in responsive UI issue #24
    assetGroupLayout: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      paddingRight: "10px", //added padding right for gap between connectivity bar and scrollbar for issue #83
      gap: "30px", // Reduced the gap value to align properly with the notification tiles. Github issue #58
      [`@media (max-width: 800px)`]: {
        //gap: "5px",
        display: "flex",
        flexDirection: "column",
        marginLeft: "5%"
      },
      // Added the media query for resolving the scroll issue. Github issue #59
      // Added media queries for larger screens for issue #82
      [`@media (min-width: 1920px) and (max-width: 2400px)`]: {
        height: "auto !important"
      },
      [`@media (min-width: 2560px) and (max-width: 3000px)`]: {
        height: "auto !important"
      }
    },
    // css class for grid view in responsive UI issue #24
    maingrid: {
      display: "grid", //added display grid for aligning notification tiles with groups or asset tile for issue #140
      columnGap: "1.5rem",
      gridTemplateColumns: "2fr 1fr", //added grid template columns for aligning notification tiles with groups or asset tile for issue #140
      [`@media (max-width: 800px)`]: {
        gridTemplateColumns: "1fr" //added this style so that tiles are stacked on top of one another in mobile view for issue #140
      }
    },
    //adding css class for styling footer div in responsive UI issue #24
    footerDiv: {
      display: "none",
      [`@media (max-width: 800px)`]: {
        marginBottom: "0",
        marginTop: "15px",
        backgroundColor: "#EEEDE7",
        display: "block"
      }
    },
    //adding css class for styling footer in responsive UI issue #24
    footerStyle: {
      [`@media (max-width: 800px)`]: {
        color: "steelblue",
        fontSize: "13px",
        paddingLeft: "8px",
        alignItems: "center"
      }
    }
  })
);