/* Issue fixed for github issue #22,issue #168,issue #97,issue #192,issue #221 */
import React, { useState, useEffect, lazy, Suspense } from "react";
import { Switch, Route, Redirect, RouteComponentProps } from "react-router-dom";

import { devicesAPI } from "services/devicesAPI";
import { useDispatch, useSelector } from "react-redux";
import LoadSpinner from "common/LoadSpinner";
import { DeviceDetailsProps } from "./device-details/types";
import { defaultDeviceDetails } from "./device-details/deviceDefaultValues";
import { setDeviceDetails } from "./device-details/deviceDetailsSlice";
import {
  deviceTabDetails,
  setDefaultDeviceTabDetails,
  setModifiedDeviceTabDetails
} from "./device-settings/deviceSettingsTabSlice";
import { SubscriptionInfo } from "./device-settings/types";
import { setCurrentDeviceId } from "features/drawer/drawerSlice";
import { setDeviceConfigurations } from "./device-settings/deviceSettingsTabSlice";
import { systemSettingsAPI } from "services/systemSettingsAPI";
import { RootState } from "app/rootReducer";

const DeviceDetails = lazy(() =>
  import("features/devices-page/device-details/DeviceDetails")
);
const DeviceSettings = lazy(() =>
  import("features/devices-page/device-settings/DeviceSettings")
);
const SavedConfigurations = lazy(() =>
  import("features/devices-page/device-configurations/SavedConfigurations")
);

const AdminDevicesContainer = ({
  // history,
  match
}: RouteComponentProps<{ deviceId: string }>) => {
  const { deviceId } = match.params;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { accountId } = useSelector(
    (state: RootState) => state.AppSlice.selectedAccount
  );

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let deviceDetails: DeviceDetailsProps = await devicesAPI.retrieveDeviceProfile(
          deviceId
        );
        //Added the call for registerDevice here as this is the main class for the device details pages. Github issue #22
        await devicesAPI.registerDeviceRequest(deviceId);
        if (
          Array.isArray(deviceDetails.subscription_info) ||
          typeof deviceDetails.subscription_info !== "object" // checking for deprecated array schema
        ) {
          deviceDetails.subscription_info = {} as SubscriptionInfo;
        }
        dispatch(setDeviceDetails(deviceDetails));
        dispatch(
          setDefaultDeviceTabDetails(updateDeviceTabDetails(deviceDetails))
        );
        dispatch(
          setModifiedDeviceTabDetails(updateDeviceTabDetails(deviceDetails))
        );
        dispatch(setCurrentDeviceId(deviceId)); // sets up the device top nav bar
        setLoading(false);
      } catch (error) {
        dispatch(setDeviceDetails(defaultDeviceDetails));
        alert(error);
        setLoading(false);
      }
    })();

    return () => {
      // reset device redux slices to default when this unmounts
      dispatch(setDeviceDetails(defaultDeviceDetails));
      dispatch(setDefaultDeviceTabDetails(deviceTabDetails));
      dispatch(setModifiedDeviceTabDetails(deviceTabDetails));
    };
  }, [deviceId, dispatch]);

  useEffect(() => {
    (async () => {
      const deviceConfigurationsList = await systemSettingsAPI.retrieveDeviceConfigurationsList(
        accountId
      );

      dispatch(setDeviceConfigurations(deviceConfigurationsList));
    })();
  }, [dispatch, accountId]);

  if (loading) {
    return (
      <div style={{ marginTop: 16 }}>
        <LoadSpinner />
      </div>
    );
  }

  return (
    <Suspense fallback={<></>}>
      <Switch>
        <Route
          exact
          path={match.url + "/device-settings"}
          render={() => <DeviceSettings deviceId={deviceId} />}
        />
        <Route
          exact
          path={`${match.url}/device-settings/saved-configurations`}
          component={SavedConfigurations}
        />
        <Route
          path={match.url}
          render={() => <DeviceDetails deviceId={deviceId} />}
        />
        <Redirect to={match.url} />
      </Switch>
    </Suspense>
  );
};

export default AdminDevicesContainer;

const updateDeviceTabDetails = (deviceDetails: DeviceDetailsProps) => {
  const {
    system_settings,
    device_local_devices,
    device_alarms,
    device_automation, //Added for automation rules for issue #97
    device_snapshots, //Added for snapshots for issue #221
    device_parameters,
    device_histograms
  } = deviceDetails;

  return {
    time_zone: system_settings?.time_zone,
    time_zone_gmt: system_settings?.time_zone_gmt,
    static_gps: system_settings?.static_gps,
    gio_location: system_settings?.gio_location,
    lpmri: system_settings?.lpmri,
    lpmwui: system_settings?.lpmwui,
    operation_mode: system_settings?.operation_mode, //Added new key for issue #168
    contract_hour: system_settings?.contract_hour //Added new key for issue #168
      ? system_settings?.contract_hour
      : deviceTabDetails.contract_hour,
    canbus_baud_rate:
      system_settings?.canbus_baud_rate != undefined //Added new key for issue #192
        ? system_settings?.canbus_baud_rate
        : deviceTabDetails.canbus_baud_rate,
    notify_pause: system_settings?.notify_pause, //Added new key for issue #168
    notify_later: system_settings?.notify_later, //Added new key for issue #168
    ip_address: system_settings?.ip_address
      ? system_settings?.ip_address
      : deviceTabDetails.ip_address,
    serial_port: system_settings?.serial_port,
    client_port: system_settings?.client_port
      ? system_settings?.client_port
      : deviceTabDetails.client_port,
    subnet_mask: system_settings?.subnet_mask
      ? system_settings?.subnet_mask
      : deviceTabDetails.subnet_mask,
    modbus_server_port: system_settings?.modbus_server_port
      ? system_settings?.modbus_server_port
      : deviceTabDetails.modbus_server_port,
    modbus_tcp_timeout: system_settings?.modbus_tcp_timeout
      ? system_settings?.modbus_tcp_timeout
      : deviceTabDetails.modbus_tcp_timeout,
    modbus_rtu_baud_rate: system_settings?.modbus_rtu_baud_rate,
    modbus_rtu_port_settings: system_settings?.modbus_rtu_port_settings,
    modbus_rtu_timeout: system_settings?.modbus_rtu_timeout
      ? system_settings?.modbus_rtu_timeout
      : deviceTabDetails.modbus_rtu_timeout,
    modbus_data_timeout: system_settings?.modbus_data_timeout,
    tenant_id: deviceDetails?.parent_tenant_id,
    device_id: deviceDetails?.device_id,
    system_settings_name: "", // ! needs to be returned
    part_number: "", // ! needs to be returned
    device_local_devices: device_local_devices,
    device_alarms: device_alarms,
    device_automation: device_automation, //setting the already selected values for automation rules for issue #97
    device_snapshots: device_snapshots, //setting the already selected values for snapshots for issue #221
    device_parameters: device_parameters,
    device_histograms: device_histograms,
    subscription_info: deviceDetails.subscription_info || {}
  };
};
