import { useEffect, useRef } from "react";
import { useAppSelector } from "app/store";

export const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return isMounted;
};

export const useAccessLevelAndRoles = () => {
  const { access_level, user_id } = useAppSelector(
    (state) => state.AppSlice.userInformation
  );
  const { role } = useAppSelector(
    (state) => state.AccountSlice.selectedAccount
  );
  const currentUserId = user_id;
  const isBasintrakSuperAdmin = access_level === "admin"; // Basintrak Super Admin employee
  const isBasintrakEngineer = access_level === "engineer"; // Basintrak engineer employee
  const isTenant = access_level === "general"; // tenant user (non-Basintrak employee)
  const isTenantAdmin = isTenant && role === "admin"; // tenant admin user
  const isTenantPowerUser = isTenant && role === "power_user"; // tenant power user
  const isTenantOfficeUser = isTenant && role === "office_user"; // tenant office user
  const isTenantFieldOperations = isTenant && role === "field_operations"; // tenant field operations user
  const isTenantFieldViewOnly = isTenant && role === "field_view_only"; //  tenant field view only user
  const isTenantNotificationsOnly = isTenant && role === "notifications"; // tenant notifications only user
  return {
    currentUserId,
    isBasintrakSuperAdmin,
    isBasintrakEngineer,
    isTenant,
    isTenantAdmin,
    isTenantPowerUser,
    isTenantOfficeUser,
    isTenantFieldOperations,
    isTenantFieldViewOnly,
    isTenantNotificationsOnly,
  };
};

export const useAppSliceState = () => {
  const { userInformation: currentLoggedInUserInformation, selectedAccount: selectedAccountInNavBar } = useAppSelector(
    (state) => state.AppSlice
  );
  const { user_id: currentLoggedInUserId, accounts: currentLoggedInUserAccounts, access_level: currentLoggedInUserAccessLevel } = currentLoggedInUserInformation;
  const { accountName: selectedAccountInNavBarName, accountId: selectedAccountInNavBarId, role: selectedAccountInNavBarRole, active: selectedAccountInNavBarActive } = selectedAccountInNavBar;
  return {
    currentLoggedInUserInformation,
    currentLoggedInUserId,
    currentLoggedInUserAccounts,
    currentLoggedInUserAccessLevel,
    selectedAccountInNavBar,
    selectedAccountInNavBarName, 
    selectedAccountInNavBarId,
    selectedAccountInNavBarRole,
    selectedAccountInNavBarActive
  };
};