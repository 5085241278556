/* Issue fixed for github issue #127*/
export const binaryFromJsonList = (num: number, json: any) => {
  const reversedBinaryArray = num
    .toString(2)
    .split("")
    .reverse();
  const jsonArray: string[] = Object.values(json); // ! might need to JSON.parse it here if string

  //returning default value when burst value is 0 for issue #127
  if (num == 0) return json["-1"];

  const convertedBinaryToText = reversedBinaryArray.reduce(
    (arr: string[], bit: string, index: number) => {
      if (parseInt(bit)) {
        arr.push(jsonArray[index]);
      }
      return arr;
    },
    []
  );

  return convertedBinaryToText.length > 1
    ? convertedBinaryToText.join(", ")
    : convertedBinaryToText[0];
};
