/* Issue fixed for github issue #60,issue #35,issue #155,issue #152,issue #277,issue #292 */
import React, { useState, useEffect, ChangeEvent } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { ActivateDeactivateUserModal } from "./ActivateDeactivateUserModal";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";
import { setCallUsers } from "../accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "app/store";
import { RootState } from "app/rootReducer";
import { regex } from "utils/regexPatterns";
// import { UsersAccounts, Group } from "./types";
import { Grid } from "@material-ui/core";
import { UserInformation } from "app/types";
import { AccountObject } from "app/types";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { InputLabel } from "components/input-label/InputLabel";
import LoadSpinner from "common/LoadSpinner";
import Button from "components/common-buttons/Button";
import Select from "react-select";
import AccountsAPI from "services/accountsAPI";
import { adminRoles, powerUserRoles } from "./roles";
import {
  RolesProp,
  RequiredUpdatedUserProps,
  UsersGroupsProps,
  AccessGroupsProps,
  ItemsProp
} from "./types";
import Xsquare from "assets/bt-assets/x-square.svg";
import { InputLength } from "common/inputLength";
import { useStyles, reactSelectStyles } from "./userModalStyles";
import { Colors } from "common/Colors";
import { LinearIndeterminate } from "common/LinearLoading";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup"; //imported for mfa for issue #152
import { ThemedRadio } from "common/ThemedRadio"; //imported for mfa for issue #152
import FormControlLabel from "@material-ui/core/FormControlLabel"; //imported for mfa for issue #152
import FormControl from "@material-ui/core/FormControl"; //imported for mfa for issue #152

type UpdateUserModalProps = {
  handleClose: () => void;
  open: boolean;
  selectedUser: UserInformation;
  userActive: boolean;
  setCallUser: Function;
};

const convertToLocalTime = (dateString: string): string => {
  // Parse the string into a Date object
  const date = new Date(dateString + " UTC");

  // Format the date to a string in the local time zone
  return date.toLocaleString();
};

export const SuperAdminUpdateUserModal: React.FC<UpdateUserModalProps> = ({
  handleClose,
  open,
  selectedUser,
  userActive,
  setCallUser
}) => {
  const classes = { ...useStyles(), ...reactSelectStyles };
  const dispatch = useDispatch();

  // const [buttonState, setButtonState] = useState("Day");
  // const [preferredContact, setPreferredContact] = useState<string[]>(["email"]);
  const [selectedUsersAccounts, setSelectedUsersAccounts] = useState<any[]>([]);
  const [preferredContact, setPreferredContact] = useState<string[]>([]);
  const [emailNotifyPreference, setEmailNotifyPreference] = useState<boolean>(
    false
  );
  const [mobileNotifyPreference, setMobileNotifyPreference] = useState<boolean>(
    false
  );
  // Adding this var for setting initial value of loss of comms when user modal is opened for issue #155
  const [lossOfCommsNotify, setLossOfCommsNotify] = useState(true);
  // const [preferredContact, setPreferredContact] = useState("Email");
  //Adding this var for mfa key for issue #152
  const [mfaOption, setMfaOption] = useState("");
  const [usersAccounts, setUsersAccounts] = useState<
    { tenantName: string; tenantId: string }[]
  >([]);
  const [linearLoading, setLinearLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // ! this will be UsersGroupsProps[][]
  const [usersGroupSelection, setUsersGroupSelection] = useState<
    UsersGroupsProps[][]
  >([]);
  const [
    restOfSelectedUsersAccounts,
    setRestOfSelectedUsersAccounts
  ] = useState<ItemsProp[]>([]);
  const [
    activateDeactivateUserModal,
    setActivateDeactivateUserModal
  ] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    errors,
    control,
    reset,
    watch
  } = useForm<RequiredUpdatedUserProps>();

  const watchBasinktrakSuperAdmin = watch("basintrak_super_admin");
  const watchBasinktrakEngineer = watch("basintrak_engineer");

  const { userInformation } = useSelector((state: RootState) => state.AppSlice);
  const { superAdminAccountId } = useAppSelector(state => state.AppSlice);

  const {
    role: selectedAccountRole,
    accountId: selectedAccountId,
    accountName: selectedAccountName
  } = useSelector((state: RootState) => state.AccountSlice.selectedAccount);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items"
  });

  //added tenant_id as tenant_id is required to get the account selected. Github issue #106
  const { tenant_name, tenant_id } = useSelector(
    (state: RootState) => state.AccountSlice.accountInformation
  );

  const closeActivateDeactivateUserModal = () => {
    setActivateDeactivateUserModal(false);
  };

  const handleCloseUpdateUserModal = () => {
    handleClose();
    const fieldsToRemove = fields.map((_, index) => index);
    remove(fieldsToRemove);
  };

  const retrievedUserGroups = async (accountId: string, index: number) => {
    if (accountId) {
      try {
        const allAccounts = await AccountsAPI.getAllTenants();
        const allTenantsGroups = allAccounts.filter(
          (account: any) => account.tenant_id === accountId
        )[0];
        const retrievedUserGroups = await AccountsAPI.retrieveGroupsInGroups([
          allTenantsGroups.master_group_id
        ]);
        const usersGroupSelectionCopy = [...usersGroupSelection];
        usersGroupSelectionCopy[index] = retrievedUserGroups;
        setUsersGroupSelection(usersGroupSelectionCopy);
        if (!fields[fields.length - 1].account) {
          setValue(`items[${[fields.length - 1]}].access_groups`, []);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const createAccountArray = (
    selectedItems: ItemsProp[],
    notificationPreferences: any,
    lossOfCommsVal: any //Adding key for issue #155
  ) => {
    if (selectedItems) {
      const selectedAccounts = selectedItems.reduce(
        (accum: any, selectedItem: any) => {
          usersAccounts.forEach((account: any) => {
            if (account.tenantId === selectedItem.account) {
              accum.push({
                address: selectedItem?.address || "",
                groups: selectedItem?.access_groups.map((item: any) => {
                  return {
                    asset_group_name: item.label,
                    asset_group_id: item.value
                  };
                }),
                // roles: [selectedItem.role],
                roles: [selectedItem.role || ""],
                notify_select:
                  notificationPreferences[selectedItem.account] == undefined
                    ? ["email"]
                    : notificationPreferences[selectedItem.account], //Added notify_select in the accounts array as it will be specific to accounts and it will update the preference only for the selected account. Github issue #106
                // Adding loss of comms key associated with specific account for issue #155
                loss_of_comms_notify:
                  lossOfCommsVal[selectedItem.account] == undefined
                    ? true
                    : lossOfCommsVal[selectedItem.account],
                tenant_id: account.tenantId,
                tenant_name: account.tenantName,
                sub_arn: selectedItem.sub_arn || "",
                active: selectedItem.active || true
              });
            }
          });
          return accum;
        },
        []
      );
      return selectedAccounts;
    }
    return [];
  };

  const updateCurrentUser = async ({
    first_name,
    last_name,
    basintrak_super_admin,
    basintrak_engineer,
    email_id,
    office_contact_number,
    mobile_number,
    items,
    mfa, //Adding mfa key for issue #152
    loss_of_comms_notify // Adding loss of comms key for issue #155
  }: RequiredUpdatedUserProps) => {
    const mobNumbers = getValues("mobile_number");
    const getEmail = getValues("email_id");
    const notificationPreferences: string[] = [];
    const jsonObj: { [key: string]: any[] } = {};
    const lossOfCommsObj: { [key: string]: any } = {}; //empty array for storing account specific loss of comms key for issue #155

    try {
      if (mobileNotifyPreference) {
        notificationPreferences.push("text");
      }
      if (emailNotifyPreference) {
        notificationPreferences.push("email");
      }
      //Added the jsonObj logic to set the notificationpreference for the selected account only, and it does not update for other accounts. Github issue #106
      var tenant = tenant_id;
      jsonObj[tenant] = notificationPreferences;
      //Added the lossOfCommsObj logic to set the enabling loss of comms for the selected account only, and it does not update for other accounts for issue #155
      lossOfCommsObj[tenant] = loss_of_comms_notify;
      for (let acc of selectedUser.accounts) {
        if (acc.tenant_id != tenant_id) {
          jsonObj[acc.tenant_id] = acc.notify_select;
          lossOfCommsObj[acc.tenant_id] = acc.loss_of_comms_notify;
        }
      }

      const updatedUserObject = {
        user_id: selectedUser.user_id,
        email: getEmail,
        given_name: first_name,
        family_name: last_name,
        accounts: [
          ...createAccountArray(items, jsonObj, lossOfCommsObj), //sending loss of comms key to be associated with specific account for issue #155
          ...restOfSelectedUsersAccounts
        ],
        phone_mobile: mobNumbers || "",
        phone_office: office_contact_number || "",
        access_level: basintrak_super_admin
          ? "admin"
          : basintrak_engineer
            ? "engineer"
            : selectedUser.access_level,
        username: email_id,
        mfa: mfaOption //Adding mfa key for issue #152
      };
      setLoading(true);
      await AccountsAPI.putSelectedUser(updatedUserObject);
      setLoading(false);
      dispatch(setCallUsers(true));
      dispatch(setSuccessModalDescription("User successfully updated!"));
      dispatch(setSuccessModalOpen(true));
      handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleClose();
    }
  };

  const handleRemoveRow = (index: number) => {
    setUsersGroupSelection(prevUserSelection => [
      ...prevUserSelection.splice(index, 1)
    ]);
    remove(index);
  };

  // Added function to handle enter button press for issue #292
  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const formatGroupLabel = (data: any) => (
    <div className={classes.group}>
      <span>{data.label}</span>
      <span className={classes.groupBadge}>{data.options.length}</span>
    </div>
  );

  useEffect(() => {
    if (selectedUser) {
      const unfilteredSelectedUsersAccounts = selectedUser.accounts.map(
        (account, index: number) => ({
          account: account.tenant_id,
          tenantId: account.tenant_id,
          tenantName: account.tenant_name,
          address: account.address,
          role: account.roles[0],
          access_groups: account.groups.map((group: AccessGroupsProps) => {
            return {
              value: group.asset_group_id,
              label: group.asset_group_name
            };
          }),
          sub_arn: account.sub_arn,
          active: account.active || true
        })
      );
      setSelectedUsersAccounts(unfilteredSelectedUsersAccounts);
      //Added the preference logic in the loop as the notification preference can be different for different accounts. Github issue #106
      for (let acc of selectedUser.accounts) {
        if (acc.tenant_id == tenant_id) {
          setPreferredContact(acc.notify_select);
          setMobileNotifyPreference(acc.notify_select?.includes("text"));
          setEmailNotifyPreference(acc.notify_select?.includes("email"));
          setLossOfCommsNotify(acc?.loss_of_comms_notify); //setting account specific value for loss of comms key as it can be different for different accounts for issue #155
        }
      }
      setMfaOption(selectedUser.mfa); //initializing mfa to already selected value for issue #152
    }
  }, [selectedUser]);

  useEffect(() => {
    (async () => {
      try {
        const allAccounts = await AccountsAPI.getAllTenants();

        const _usersAccounts = allAccounts.map((account: AccountObject) => ({
          tenantName: account.tenant_name,
          tenantId: account.tenant_id
        }));
        setUsersAccounts(_usersAccounts);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [userInformation.accounts]);

  useEffect(() => {
    (async () => {
      if (selectedUser.accounts.length) {
        const unfilteredSelectedUsersAccounts = selectedUser.accounts.map(
          (account, index: number) => ({
            account: account.tenant_id,
            tenantId: account.tenant_id,
            tenant_name: account.tenant_name,
            address: account.address,
            role: account.roles[0],
            access_groups: account.groups.map((group: AccessGroupsProps) => {
              return {
                value: group.asset_group_id,
                label: group.asset_group_name
              };
            }),
            sub_arn: account.sub_arn,
            active: account.active || true
          })
        );

        const unselectedUsers = [...selectedUser.accounts];

        const filteredSelectedUsers = unfilteredSelectedUsersAccounts.reduce(
          (accum: any, selectedItem: ItemsProp) => {
            const selectedIndex = unselectedUsers.findIndex(
              (itemToFind: AccountObject) =>
                selectedItem.account === itemToFind.tenant_id
            );
            unselectedUsers.splice(selectedIndex, 1);
            accum = unselectedUsers;

            return accum;
          },
          []
        );
        setRestOfSelectedUsersAccounts(filteredSelectedUsers);

        const allAccounts = await AccountsAPI.getAllTenants();

        const _usersAccounts = await allAccounts.map(
          (account: AccountObject) => ({
            tenantName: account.tenant_name,
            tenantId: account.tenant_id
          })
        );

        const usersMatches = selectedUser.accounts.reduce(
          (accum: any, selectedUsers: AccountObject) => {
            _usersAccounts.forEach((userAccount: any) => {
              if (userAccount.tenantId === selectedUsers.tenant_id) {
                accum.push(selectedUsers);
              }
            });
            return accum;
          },
          []
        );
        setLinearLoading(true);
        let userGroupSelections = [];
        const assetGroupIdsArray = usersMatches.map(
          ({ groups }: AccountObject) =>
            groups.map(({ asset_group_id }: AccessGroupsProps) => {
              return asset_group_id;
            })
        );
        try {
          for (let index = 0; index < assetGroupIdsArray.length; index++) {
            const retrievedUserGroups = await AccountsAPI.retrieveGroupsInGroups(
              assetGroupIdsArray[index]
            );
            userGroupSelections.push(retrievedUserGroups);
          }
          setUsersGroupSelection(userGroupSelections);
          setLinearLoading(false);
        } catch (error) {
          console.log(error);
          setLinearLoading(false);
        }

        setTimeout(() => {
          reset({
            items: unfilteredSelectedUsersAccounts
          });
        }, 1.5);
      }
    })();
  }, [reset, selectedUser, userInformation.accounts, open]);

  const localTime = selectedUser.last_login_time
    ? convertToLocalTime(selectedUser.last_login_time)
    : null;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalOverlay}>
          {loading ? (
            <LoadSpinner />
          ) : (
            <form onSubmit={handleSubmit(updateCurrentUser)}>
              <Grid container className={classes.modalBody}>
                <Grid item xs={12} className={classes.header}>
                  <p className={classes.headerText}>
                    {selectedUser.given_name} {selectedUser.family_name}
                  </p>
                  <CloseIcon
                    className={classes.closeIcon}
                    onClick={handleCloseUpdateUserModal}
                  />
                </Grid>
                <div className={classes.innerInputs}>
                  <Grid item xs={12} className={classes.inputGroup}>
                    <div
                      className={classes.accessAccount}
                      style={{ justifyContent: "space-between" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "baseline"
                        }}
                      >
                        In account:{" "}
                        <p style={{ paddingLeft: ".5rem" }}>{tenant_name}</p>
                      </div>
                      <div>
                        Status:{" "}
                        {userActive ? (
                          <span className={classes.active}>Active</span>
                        ) : (
                          <span className={classes.notActive}>Not active</span>
                        )}
                      </div>
                      <div>
                        Last login time:{" "}
                        {localTime}
                      </div>
                    </div>
                  </Grid>
                  {userInformation.access_level === "admin" &&
                    superAdminAccountId === selectedAccountId ? (
                    <Grid
                      container
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end"
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end"
                        }}
                      >
                        <div
                          style={{
                            display: "inline-flex",
                            justifyContent: "flex-end",
                            alignItems: "center"
                          }}
                        >
                          <InputLabel
                            label="Basintrak Super Admin?"
                            name="basintrak_super_admin"
                            errors={errors}
                            isRequired={false}
                          />
                          <Controller
                            control={control}
                            name="basintrak_super_admin"
                            defaultValue={selectedUser.access_level === "admin"}
                            rules={{
                              required: false
                            }}
                            render={(
                              { onChange, onBlur, value, name, ref },
                              { invalid, isTouched, isDirty }
                            ) => (
                              <Checkbox
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                                disabled={watchBasinktrakEngineer}
                              />
                            )}
                          />
                        </div>
                        <div
                          style={{
                            display: "inline-flex",
                            justifyContent: "flex-end",
                            alignItems: "center"
                          }}
                        >
                          <InputLabel
                            label="Basintrak Engineer?"
                            name="basintrak_engineer"
                            errors={errors}
                            isRequired={false}
                          />
                          <Controller
                            control={control}
                            name="basintrak_engineer"
                            defaultValue={
                              selectedUser.access_level === "engineer"
                            }
                            rules={{
                              required: false
                            }}
                            render={(
                              { onChange, onBlur, value, name, ref },
                              { invalid, isTouched, isDirty }
                            ) => (
                              <Checkbox
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                                disabled={watchBasinktrakSuperAdmin}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}></Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={6}>
                      <div className={classes.inputGroup}>
                        <InputLabel
                          label="First name"
                          name="first_name"
                          errors={errors}
                        />
                        <input
                          name="first_name"
                          ref={register({
                            required: "This field is required.",
                            pattern: {
                              value: /^[a-zA-Z_ ]*$/,
                              message: "Letters only."
                            }
                          })}
                          // Added function to handle enter button press for issue #292
                          onKeyPress={handleKeyPress}
                          defaultValue={selectedUser.given_name}
                          maxLength={InputLength.FirstName}
                          type="text"
                          placeholder="Enter"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.inputGroupRight}>
                        <InputLabel
                          label="Last name"
                          name="last_name"
                          errors={errors}
                        />
                        <input
                          name="last_name"
                          ref={register({
                            required: "This field is required.",
                            pattern: {
                              value: /^[a-zA-Z_ ]*$/,
                              message: "Letters only."
                            }
                          })}
                          // Added function to handle enter button press for issue #292
                          onKeyPress={handleKeyPress}
                          defaultValue={selectedUser.family_name}
                          maxLength={InputLength.LastName}
                          type="text"
                          placeholder="Enter"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={6}>
                      <div className={classes.inputGroup}>
                        <div className={classes.labelContainer}>
                          <InputLabel
                            label="Email"
                            name="email_id"
                            errors={errors}
                            style={{ width: "100%", paddingRight: 3 }}
                          />
                        </div>
                        <input
                          name="email_id"
                          ref={register({
                            required: "This field is required.",
                            pattern: {
                              value: /^\S+@\S+\.\S+$/,
                              message: "Not a valid email."
                            }
                          })}
                          // Added function to handle enter button press for issue #292
                          onKeyPress={handleKeyPress}
                          defaultValue={selectedUser.email}
                          maxLength={InputLength.Email}
                          type="text"
                          placeholder="Enter"
                          disabled={true}  //for graying out the email id for issue #277
                        />
                      </div>
                    </Grid>
                    {/* Adding checkbox for email notification preference instead of heart icon for issue #152 */}
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <div
                        className={classes.inputGroupRightCheckbox}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Controller
                          control={control}
                          name="emailNotify"
                          defaultValue={emailNotifyPreference}
                          rules={{
                            required: false
                          }}
                          render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                          ) => (
                            <Checkbox
                              onBlur={onBlur}
                              onClick={() => {
                                setEmailNotifyPreference(
                                  !emailNotifyPreference
                                );
                              }}
                              onChange={e => onChange(e.target.checked)}
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />
                        <InputLabel
                          label="Email notifications"
                          name="emailNotify"
                          errors={errors}
                          isRequired={false}
                        />
                      </div>
                      {/* Adding checkbox for mobile notification preference instead of heart icon for issue #152 */}
                      <div
                        className={classes.inputGroupRightCheckbox}
                        style={{
                          display: "flex", alignItems: "center", opacity: !selectedUser?.mobile_verified
                            ? "0.55"
                            : "1"
                        }}
                      >
                        <Controller
                          control={control}
                          name="mobileNotify"
                          defaultValue={mobileNotifyPreference}
                          rules={{
                            required: false
                          }}
                          render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                          ) => (
                            <Checkbox
                              onBlur={onBlur}
                              onClick={() => {
                                setMobileNotifyPreference(
                                  !mobileNotifyPreference
                                );
                              }}
                              onChange={e => onChange(e.target.checked)}
                              disabled={!selectedUser?.mobile_verified}
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />
                        <InputLabel
                          label="Mobile notifications"
                          name="mobileNotify"
                          errors={errors}
                          isRequired={false}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={6} style={{ overflowY: "auto" }}>
                      <div className={classes.inputGroup}>
                        <div className={classes.labelContainer}>
                          <InputLabel
                            label="Mobile number"
                            name="mobile_number"
                            errors={errors}
                            style={{ width: "100%", paddingRight: 3 }}
                          />
                        </div>
                        <input
                          name="mobile_number"
                          ref={register({
                            required: "This field is required.",
                            pattern: {
                              value: regex.validPhone,
                              message: "Please enter in format xxx-xxx-xxxx"
                            }
                          })}
                          // Added function to handle enter button press for issue #292
                          onKeyPress={handleKeyPress}
                          defaultValue={selectedUser.phone_mobile}
                          type="text"
                          placeholder="Enter"
                          disabled={true}
                        />
                      </div>
                    </Grid>
                    {/* Adding mfa checkbox for issue #152 */}
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <div className={classes.inputGroupRight}>
                        <InputLabel
                          label="Multi-factor Authentication"
                          name="mfa"
                          errors={errors}
                        />
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="position"
                            name="position"
                            style={{ display: "flex", flexDirection: "row" }}
                            value={mfaOption}
                            defaultValue={selectedUser?.mfa}
                            onChange={(event: any) => {
                              setMfaOption(event.target.value);
                            }}
                          >
                            <FormControlLabel
                              classes={{
                                label: classes.label,
                                root: classes.formRoot
                              }}
                              value="0"
                              // disabling mfa options when mobile isn't verified for issue #152
                              disabled={!selectedUser?.mobile_verified}
                              style={{
                                opacity: !selectedUser?.mobile_verified
                                  ? "0.55"
                                  : "1"
                              }}
                              control={<ThemedRadio size="small" />}
                              label="None"
                            />
                            {/* <FormControlLabel
                              classes={{
                                label: classes.label,
                                root: classes.formRoot
                              }}
                              value="1"
                              control={<ThemedRadio size="small" />}
                              label="Email"
                            /> */}
                            <FormControlLabel
                              classes={{
                                label: classes.label,
                                root: classes.formRoot
                              }}
                              value="1"
                              // disabling mfa options when mobile isn't verified for issue #152
                              disabled={!selectedUser?.mobile_verified}
                              style={{
                                opacity: !selectedUser?.mobile_verified
                                  ? "0.55"
                                  : "1"
                              }}
                              control={<ThemedRadio size="small" />}
                              label="SMS"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.inputGroup}>
                        <div className={classes.labelContainer}>
                          <InputLabel
                            label="Office contact number"
                            name="office_contact_number"
                            errors={errors}
                            style={{ width: "100%", paddingRight: 3 }}
                            isRequired={false}
                          />
                        </div>
                        <input
                          name="office_contact_number"
                          ref={register({
                            required: false,
                            pattern: {
                              value: regex.validPhone,
                              message: "Please enter in format xxx-xxx-xxxx"
                            }
                          })}
                          // Added function to handle enter button press for issue #292
                          onKeyPress={handleKeyPress}
                          defaultValue={selectedUser.phone_office}
                          type="text"
                          placeholder="Enter"
                        />
                      </div>
                    </Grid>
                    {/* Added checkbox with text for enabling loss of comms for issue #155 */}
                    <Grid
                      item
                      xs={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div className={classes.inputGroupRightCheckbox}>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "inline-flex",
                            justifyContent: "flex-end",
                            alignItems: "center"
                          }}
                        >
                          <Controller
                            control={control}
                            name="loss_of_comms_notify"
                            defaultValue={lossOfCommsNotify}
                            rules={{
                              required: false
                            }}
                            render={(
                              { onChange, onBlur, value, name, ref },
                              { invalid, isTouched, isDirty }
                            ) => (
                              <Checkbox
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                              />
                            )}
                          />
                          <InputLabel
                            label="Enable loss of comms notifications"
                            name="loss_of_comms_notify"
                            errors={errors}
                            isRequired={false}
                          />
                        </Grid>
                      </div>
                    </Grid>
                    {linearLoading && <LinearIndeterminate />}
                    {!linearLoading &&
                      fields.map(
                        (
                          {
                            id,
                            account,
                            role,
                            address,
                            sub_arn,
                            active,
                            access_groups,
                            match
                          },
                          index
                        ) => {
                          const previousAccountIds: string[] = fields
                            .slice(-1, index) //changed from 0 to -1 for issue #35
                            .map(field => field.account);

                          return (
                            <Grid
                              container
                              className={classes.accountInformation}
                              key={id}
                            >
                              {selectedAccountId !== superAdminAccountId && (
                                <>
                                  <Grid
                                    item
                                    xs={3}
                                    className={classes.inputGroupRight}
                                    style={{ padding: "1rem 0" }}
                                  >
                                    <InputLabel
                                      label="Account"
                                      name={`items[${index}].account`}
                                      errors={errors}
                                    />
                                    <select
                                      name={`items[${index}].account`}
                                      ref={register({
                                        required: "Choose account."
                                      })}
                                      // Added function to handle enter button press for issue #292
                                      onKeyPress={handleKeyPress}
                                      defaultValue={account}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLSelectElement>
                                      ) => {
                                        retrievedUserGroups(
                                          e.target.value,
                                          index
                                        );
                                      }}
                                      style={
                                        account
                                          ? {
                                            pointerEvents: "none",
                                            opacity: 0.8
                                          }
                                          : {}
                                      }
                                    >
                                      <option value="">
                                        Select account...
                                      </option>
                                      {usersAccounts
                                        .filter(
                                          ({ tenantId }) =>
                                            !previousAccountIds.includes(
                                              tenantId
                                            )
                                        )
                                        .map(
                                          (
                                            userAccount: {
                                              tenantId: string;
                                              tenantName: string;
                                            },
                                            index: number
                                          ) => (
                                            <option
                                              key={
                                                userAccount.tenantName + index
                                              }
                                              value={userAccount.tenantId}
                                            >
                                              {userAccount.tenantName}
                                            </option>
                                          )
                                        )}
                                    </select>
                                  </Grid>
                                  {account !== superAdminAccountId && (
                                    <Grid
                                      item
                                      xs={3}
                                      className={classes.inputGroupRight}
                                    >
                                      <InputLabel
                                        label="Role"
                                        name={`items[${index}].role`}
                                        errors={errors}
                                      />
                                      <select
                                        name={`items[${index}].role`}
                                        ref={register()}
                                        // Added function to handle enter button press for issue #292
                                        onKeyPress={handleKeyPress}
                                        defaultValue={role}
                                      >
                                        <option value="">Select role...</option>
                                        {adminRoles.map(
                                          (role: RolesProp, index: number) => (
                                            <option
                                              key={role.name + index}
                                              value={role.value}
                                            >
                                              {role.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </Grid>
                                  )}
                                  <Grid
                                    item
                                    xs={5}
                                    className={classes.inputGroupRight}
                                  >
                                    <InputLabel
                                      label="Access group(s)"
                                      name={`items[${index}].access_groups`}
                                      errors={errors}
                                      isRequired={false}
                                    />
                                    <Controller
                                      render={props => {
                                        return (
                                          <Select
                                            {...props}
                                            menuPosition={"fixed"}
                                            styles={reactSelectStyles}
                                            // defaultValue={access_groups}
                                            defaultValue={
                                              account && access_groups
                                                ? access_groups
                                                : "Select..."
                                            }
                                            formatGroupLabel={formatGroupLabel}
                                            options={usersGroupSelection[index]}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isMulti
                                          />
                                        );
                                      }}
                                      name={`items[${index}].access_groups`}
                                      // defaultValue={access_groups}
                                      defaultValue={
                                        account && access_groups
                                          ? access_groups
                                          : "Select..."
                                      }
                                      type="select"
                                      control={control}
                                      rules={{
                                        required: false
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={1}
                                    className={classes.inputGroupRight}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginTop: "1rem"
                                    }}
                                  >
                                    {/* {account !== superAdminAccountId ? ( */}
                                    {/* Updated to index>0 from index for issue #60 */}
                                    {userInformation.user_id !==
                                      selectedUser.user_id && index > 0 ? (
                                      <img
                                        src={Xsquare}
                                        alt="Delete Account"
                                        style={{
                                          color: Colors.BasinRed,
                                          cursor: "pointer"
                                        }}
                                        onClick={() => handleRemoveRow(index)}
                                      />
                                    ) : null}
                                  </Grid>
                                  <input // hidden input for account address - needs to be passed here in order to return its value in fieldArray
                                    name={`items[${index}].address`}
                                    ref={register({ required: false })}
                                    defaultValue={address}
                                    type="hidden"
                                  />
                                  <input // hidden input for account sub_arn - needs to be passed here in order to return its value in fieldArray
                                    name={`items[${index}].sub_arn`}
                                    ref={register({ required: false })}
                                    defaultValue={sub_arn}
                                    type="hidden"
                                  />
                                  <input // hidden input for active prop in selected user's account obj - needs to be passed here in order to return its value in fieldArray
                                    name={`items[${index}].active`}
                                    ref={register({ required: false })}
                                    defaultValue={active}
                                    type="hidden"
                                  />
                                </>
                              )}

                              {selectedAccountId === superAdminAccountId && (
                                <>
                                  <Grid
                                    item
                                    xs={3}
                                    className={classes.inputGroupRight}
                                    style={{ padding: "1rem 0" }}
                                  >
                                    <InputLabel
                                      label="Account"
                                      name={`items[${index}].account`}
                                      errors={errors}
                                    />
                                    <select
                                      name={`items[${index}].account`}
                                      ref={register({
                                        required: "Choose account."
                                      })}
                                      // Added function to handle enter button press for issue #292
                                      onKeyPress={handleKeyPress}
                                      defaultValue={account}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLSelectElement>
                                      ) => {
                                        retrievedUserGroups(
                                          e.target.value,
                                          index
                                        );
                                      }}
                                      style={
                                        account
                                          ? {
                                            pointerEvents: "none",
                                            opacity: 0.8
                                          }
                                          : {}
                                      }
                                    >
                                      <option value="">
                                        Select account...
                                      </option>
                                      {usersAccounts
                                        .filter(
                                          ({ tenantId }) =>
                                            !previousAccountIds.includes(
                                              tenantId
                                            )
                                        )
                                        .map(
                                          (
                                            account: {
                                              tenantId: string;
                                              tenantName: string;
                                            },
                                            index: number
                                          ) => (
                                            <option
                                              key={account.tenantName + index}
                                              value={account.tenantId}
                                            >
                                              {account.tenantName}
                                            </option>
                                          )
                                        )}
                                    </select>
                                  </Grid>
                                  {account !== superAdminAccountId && (
                                    <Grid
                                      item
                                      xs={3}
                                      className={classes.inputGroupRight}
                                    >
                                      <InputLabel
                                        label="Role"
                                        name={`items[${index}].role`}
                                        errors={errors}
                                      />
                                      <select
                                        name={`items[${index}].role`}
                                        ref={register()}
                                        // Added function to handle enter button press for issue #292
                                        onKeyPress={handleKeyPress}
                                        defaultValue={role}
                                      >
                                        <option value="">Select role...</option>
                                        {adminRoles.map(
                                          (role: RolesProp, index: number) => (
                                            <option
                                              key={role.name + index}
                                              value={role.value}
                                            >
                                              {role.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </Grid>
                                  )}

                                  <Grid
                                    item
                                    xs={5}
                                    className={classes.inputGroupRight}
                                  >
                                    <InputLabel
                                      label="Access group(s)"
                                      name={`items[${index}].access_groups`}
                                      errors={errors}
                                      isRequired={false}
                                    />
                                    <Controller
                                      render={props => {
                                        return (
                                          <Select
                                            {...props}
                                            menuPosition={"fixed"}
                                            styles={reactSelectStyles}
                                            defaultValue={
                                              account && access_groups
                                                ? access_groups
                                                : "Select..."
                                            }
                                            formatGroupLabel={formatGroupLabel}
                                            options={usersGroupSelection[index]}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isMulti
                                          />
                                        );
                                      }}
                                      name={`items[${index}].access_groups`}
                                      // defaultValue={access_groups}
                                      defaultValue={
                                        account && access_groups
                                          ? access_groups
                                          : "Select..."
                                      }
                                      type="select"
                                      control={control}
                                      rules={{
                                        required: false
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={1}
                                    className={classes.inputGroupRight}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginTop: "1rem"
                                    }}
                                  >
                                    {/* Updated to index>0 from index for issue #60 */}
                                    {index > 0 &&
                                      superAdminAccountId !== account ? (
                                      <img
                                        src={Xsquare}
                                        alt="Delete Account"
                                        style={{
                                          color: Colors.BasinRed,
                                          cursor: "pointer"
                                        }}
                                        onClick={() => handleRemoveRow(index)}
                                      />
                                    ) : null}
                                  </Grid>
                                  <input // hidden input for account address - needs to be passed here in order to return its value in fieldArray
                                    name={`items[${index}].address`}
                                    ref={register({ required: false })}
                                    defaultValue={address}
                                    type="hidden"
                                  />
                                  <input // hidden input for account sub_arn - needs to be passed here in order to return its value in fieldArray
                                    name={`items[${index}].sub_arn`}
                                    ref={register({ required: false })}
                                    defaultValue={sub_arn}
                                    type="hidden"
                                  />
                                  <input // hidden input for active prop in selected user's account obj - needs to be passed here in order to return its value in fieldArray
                                    name={`items[${index}].active`}
                                    ref={register({ required: false })}
                                    defaultValue={active}
                                    type="hidden"
                                  />
                                </>
                              )}
                            </Grid>
                          );
                        }
                      )}
                  </Grid>
                </div>
                <Grid item xs={12} className={classes.actionItems}>
                  <Button
                    type="button"
                    color={
                      fields.length >= usersAccounts.length
                        ? "white"
                        : "defaultTheme"
                    }
                    onClick={() => append({})}
                    disabled={fields.length >= usersAccounts.length}
                  >
                    + Add account
                  </Button>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      type="submit"
                      color="white"
                      onClick={handleCloseUpdateUserModal}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="defaultTheme">
                      Save
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </div>
      </Modal>
      {/* <ActivateDeactivateUserModal
        open={activateDeactivateUserModal}
        handleClose={closeActivateDeactivateUserModal}
        userActivity={userActivity}
        tenantId={selectedAccountId}
        userId={selectedUser.user_id}
        setCallUser={setCallUser}
      /> */}
    </div>
  );
};

export default SuperAdminUpdateUserModal;
