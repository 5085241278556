/* Issue fixed for github issue #26,issue #287 */
import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useStyles } from "./accountStyles";
import { useSelector, useDispatch } from "react-redux";
import { setUserInformation } from "app/appSlice";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";
import { setAccountLoading, setAccountDashboard } from "../accountSlice";
import { RootState } from "app/rootReducer";
import AddNewAccountModal from "./AddNewAccountModal";
import Checkbox from "@material-ui/core/Checkbox";
import AccountSuccessModal from "features/accounts-page/components/AccountSuccessModal";
import AccountAPI from "services/accountsAPI";
import clsx from "clsx";
import { regex } from "utils/regexPatterns";
import { InputLength } from "common/inputLength";
import { PostTenantObject } from "app/types";
import { useForm } from "react-hook-form";
import { InputLabel } from "components/input-label/InputLabel";
import Button from "components/common-buttons/Button";

const StyledCheckbox = ({
  checked,
  handleChange,
  name,
}: {
  checked: boolean;
  handleChange: any;
  name: string;
}) => {
  const classes = useStyles();

  return (
    <Checkbox
      name={name}
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      checked={checked}
      onChange={handleChange}
    />
  );
};

type AddNewAccountProps = {
  handleCancel: Function;
  getaddNewAccount: Function; // Accepting the function to redirect to the main account page. Github issue #26
  // parentTenantId: string;
};

const AddNewAccount: React.FC<AddNewAccountProps> = ({
  handleCancel,
  getaddNewAccount, // Accepting the function to redirect to the main account page. Github issue #26
  // parentTenantId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [givenName, setgivenName] = useState("");
  const [familyName, setfamilyName] = useState("");
  const [email, setemail] = useState("");
  const [filledFormObject, setfilledFormObject] = useState({
    tenant_name: "",
    admin: {
      phone: "",
      given_name: "",
      family_name: "",
      email: "",
    },
    billing_address: {
      zip_code: "",
      city: "",
      state_province: "",
      address_line: "",
    },
    shipping_address: {
      zip_code: "",
      city: "",
      state_province: "",
      address_line: "",
    },
  });

  const history = useHistory();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    clearErrors,
    errors,
    // formState,
  } = useForm<PostTenantObject>();
  const watchBilling = watch([
    "billing_address.zip_code",
    "billing_address.city",
    "billing_address.state_province",
    "billing_address.address_line",
    // "shipping_address.zip_code",
    // "shipping_address.city",
    // "shipping_address.state_province",
    // "shipping_address.address_line",
  ]);

  const logginInUserInformation = useSelector(
    (state: RootState) => state.AppSlice.userInformation
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    clearErrors("shipping_address");
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setUserId(logginInUserInformation.user_id);
    setgivenName(logginInUserInformation.given_name);
    setfamilyName(logginInUserInformation.family_name);
    setemail(logginInUserInformation.email);
  }, [logginInUserInformation]);

  useEffect(() => {
    const zipCodeBilling = getValues("billing_address.zip_code");
    const cityBilling = getValues("billing_address.city");
    const stateProvinceBilling = getValues("billing_address.state_province");
    const addressBilling = getValues("billing_address.address_line");

    if (checked) {
      setValue("shipping_address.zip_code", zipCodeBilling, {
        shouldValidate: true,
      });
      setValue("shipping_address.city", cityBilling, { shouldValidate: true });
      setValue("shipping_address.state_province", stateProvinceBilling, {
        shouldValidate: true,
      });
      setValue("shipping_address.address_line", addressBilling, {
        shouldValidate: true,
      });
    }
  }, [checked, watchBilling, getValues, setValue]);

  useEffect(() => {
    if (!checked) {
      setValue("shipping_address.zip_code", "", {
        shouldValidate: false,
      });
      setValue("shipping_address.city", "", { shouldValidate: false });
      setValue("shipping_address.state_province", "", {
        shouldValidate: false,
      });
      setValue("shipping_address.address_line", "", {
        shouldValidate: false,
      });
    }
  }, [checked, setValue, clearErrors]);

  const handleCreateNewAccount = async () => {
    const postTenantObject = {
      ...filledFormObject,
      user_id: userId,
      is_same_as_billing: checked,
      cust_app_id: "1"
    };
    try {
      dispatch(setAccountLoading(true));
      await AccountAPI.postTenant(postTenantObject);
      // ! will there be some logic to basintrak user and customer user on updating an account
      const updatedUserInformation = await AccountAPI.getUserProfile(
        logginInUserInformation.user_id
      );
      dispatch(setUserInformation(updatedUserInformation));
      // ! temporary replacement to access_level general
      // dispatch(
      //   setUserInformation({
      //     ...updatedUserInformation,
      //     access_level: "general",
      //   })
      // );
      getaddNewAccount(); // Added the function call to redirect to dashboard. Github issue #26
      dispatch(setAccountDashboard(true)); // Added the dispatch function call and set it to true to redirect to dashboard. Github issue #26
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription("Account successfully created!"));
      dispatch(setAccountLoading(false));
      handleClose();
      history.push("/accounts");
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        alert(error.response.data);
      } else if (error.request) {
        // the request was made but no response was received
        alert("Error creating new account: Access Denied");
      } else {
        // Something happened in setting up the request that triggered an Error
        alert("Error creating new account. Please try again.");
      }
      dispatch(setAccountLoading(false));
      handleClose();
    }
  };

  const openNewAccountModal = (data: any) => {
    setfilledFormObject(data);
    setOpen(true);
  };

  return (
    <>
      <form onSubmit={handleSubmit((data: any) => openNewAccountModal(data))}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.inputGroup} style={{ width: "50%" }}>
              <InputLabel
                label="Account name"
                name="tenant_name"
                errors={errors}
              />
              <input
                name="tenant_name"
                maxLength={InputLength.AccountName}
                ref={register({
                  required: "This field is required.",
                  maxLength: {
                    value: InputLength.AccountName,
                    message: `Account name must be less than $ {InputLength.AccountName}characters.`,
                  },
                })}
                type="text"
                placeholder="Enter"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            {/* Made first name,last name and email editable for issue #287 */}
            <div className={classes.inputGroup}>
              <div className={classes.labelContainer}>
                <InputLabel
                  label="Admin first name"
                  name="admin.given_name"
                  errors={errors}
                />
              </div>
              <input
                name="admin.given_name"
                ref={register({
                  required: "This field is required.",
                  maxLength: {
                    value: InputLength.FirstName,
                    message: `First name must be less than $ {InputLength.FirstName}`,
                  },
                })}
                type="text"
                placeholder="Enter"
                defaultValue={givenName}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.inputGroup}>
              <InputLabel
                label="Admin last name"
                name="admin.family_name"
                errors={errors}
              />
              <input
                name="admin.family_name"
                ref={register({
                  required: "This field is required.",
                  maxLength: {
                    value: InputLength.LastName,
                    message: `Last name must be less than $ {InputLength.LastName}`,
                  },
                })}
                type="text"
                placeholder="Enter"
                defaultValue={familyName}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.inputGroup}>
              <InputLabel
                label="Admin email"
                name="admin.email"
                errors={errors}
              />
              <input
                name="admin.email"
                ref={register({
                  required: "This field is required.",
                  maxLength: {
                    value: InputLength.Email,
                    message: `Email must be less than $ {InputLength.Email}`,
                  },
                  pattern: {
                    value: regex.validEmail,
                    message: "Invalid Email",
                  },
                })}
                type="text"
                placeholder="Enter valid email"
                defaultValue={email}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.inputGroup}>
              <InputLabel
                label=" Admin contact number"
                name="admin.phone"
                errors={errors}
              />
              <input
                name="admin.phone"
                ref={register({
                  required: "This field is required.",
                  pattern: {
                    value: regex.validPhone,
                    message:
                      "Invalid phone number - please enter in format xxx-xxx-xxxx",
                  },
                })}
                type="text"
                placeholder="Enter valid contact number"
              />
            </div>
          </Grid>
          <hr className={classes.lineBreak} />
          <Grid item xs={12} className={classes.header}>
            <div> Billing address: </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.inputGroup}>
              <InputLabel
                label="Zip code"
                name="billing_address.zip_code"
                errors={errors}
              />
              <input
                name="billing_address.zip_code"
                ref={register({
                  required: "This field is required.",
                  pattern: {
                    value: regex.validNumbersOnly,
                    message: "Invalid zip code",
                  },
                })}
                maxLength={InputLength.ZipCode}
                type="text"
                placeholder="Enter"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.inputGroup}>
              <InputLabel
                label="City"
                name="billing_address.city"
                errors={errors}
              />
              <input
                name="billing_address.city"
                ref={register({
                  required: "This field is required.",
                  minLength: {
                    value: 2,
                    message: "Please enter more than 1 character ",
                  },
                })}
                maxLength={InputLength.City}
                type="text"
                placeholder="Enter"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.inputGroup}>
              <InputLabel
                label="State/Province"
                name="billing_address.state_province"
                errors={errors}
              />
              <input
                name="billing_address.state_province"
                ref={register({
                  required: "This field is required.",
                  minLength: {
                    value: 2,
                    message: "Please enter more than 1 character ",
                  },
                })}
                maxLength={InputLength.StateProvince}
                type="text"
                placeholder="Enter"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.inputGroup}>
              <InputLabel
                label="Address line"
                name="billing_address.address_line"
                errors={errors}
              />
              <input
                name="billing_address.address_line"
                ref={register({
                  required: "This field is required.",
                  minLength: {
                    value: 2,
                    message: "Please enter more than 1 character ",
                  },
                  pattern: {
                    value: regex.validAddress,
                    message:
                      "Please enter a valid address - no special charaters",
                  },
                })}
                maxLength={InputLength.Address}
                type="text"
                placeholder="Enter"
              />
            </div>
          </Grid>
          <hr className={classes.lineBreak} />
          <Grid item xs={12} className={classes.header}>
            <div style={{ margin: "0 1rem 0 0" }}> Shipping address: </div>
            <label style={{ fontWeight: 400, fontSize: 14 }}>
              <StyledCheckbox
                checked={checked}
                handleChange={handleChange}
                name="is_same_as_billing"
              />
              Same as billing address
            </label>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.inputGroup}>
              <InputLabel
                label="Zip code"
                name="shipping_address.zip_code"
                errors={errors}
              />
              <input
                name="shipping_address.zip_code"
                ref={register({
                  required: "This field is required.",
                  pattern: {
                    value: regex.validNumbersOnly,
                    message: "Invalid zip code",
                  },
                })}
                maxLength={InputLength.ZipCode}
                type="text"
                placeholder="Enter"
                readOnly={checked}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.inputGroup}>
              <InputLabel
                label="City"
                name="shipping_address.city"
                errors={errors}
              />
              <input
                name="shipping_address.city"
                ref={register({
                  required: "This field is required.",
                  minLength: {
                    value: 2,
                    message: "Please enter more than 1 character ",
                  },
                })}
                maxLength={InputLength.City}
                type="text"
                placeholder="Enter"
                readOnly={checked}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.inputGroup}>
              <InputLabel
                label="State/Province"
                name="shipping_address.state_province"
                errors={errors}
              />
              <input
                name="shipping_address.state_province"
                ref={register({
                  required: "This field is required.",
                  minLength: {
                    value: 2,
                    message: "Please enter more than 1 character ",
                  },
                })}
                maxLength={InputLength.StateProvince}
                type="text"
                placeholder="Enter"
                readOnly={checked}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.inputGroup}>
              <InputLabel
                label="Address line"
                name="shipping_address.address_line"
                errors={errors}
              />
              <input
                name="shipping_address.address_line"
                ref={register({
                  required: "This field is required.",
                  minLength: {
                    value: 2,
                    message: "Please enter more than 1 character ",
                  },
                  pattern: {
                    value: regex.validAddress,
                    message:
                      "Please enter a valid address - no special charaters",
                  },
                })}
                maxLength={InputLength.Address}
                type="text"
                placeholder="Enter"
                readOnly={checked}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button type="button" color="white" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button type="submit" color="defaultTheme">
              Create account
            </Button>
          </Grid>
        </Grid>
      </form>
      <AddNewAccountModal
        handleCreateNewAccount={handleCreateNewAccount}
        handleClose={handleClose}
        open={open}
      />
      <AccountSuccessModal handleCancel={handleCancel} />
    </>
  );
};

export default AddNewAccount;
