/*Issue fixed for issue #66 */
import React, { useState, useEffect } from "react";
import RulotechLogo from "assets/bt-logins/rulo_logo_2.svg";//imported rulotech logo for issue #66
import { TextField, InputAdornment } from "@material-ui/core";
import { ForgotPasswordProps } from "./types";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import envelopeIcon from "assets/bt-logins/login_email.svg";
import envelopeIconError from "assets/bt-logins/Login_email_red.svg";
import { validateEmail } from "utils/helpers";
import { setForgottenEmail } from "app/appSlice";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import clsx from "clsx";
import arrowLeft from "assets/bt-logins/arrow-left-circle.svg";
import { Colors } from "common/Colors";

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  setLogType,
  onStateChange,
}: ForgotPasswordProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);

  const getCode = async () => {
    if (!validateEmail(email)) {
      setEmailValid(false);
      return;
    }

    try {
      await Auth.forgotPassword(email);
      dispatch(setForgottenEmail(email));
      setLogType("setNewPassword");
    } catch (error) {
      // console.log("error", "Incorrect verification code");
      alert(error.message);
    }
  };

  useEffect(() => {
    if (email.length === 0 || validateEmail(email)) {
      setEmailValid(true);
    }
  }, [email]);

  return (
    <div>
      {/* Changed img src to RulotechLogo and added css class rulotechlogo for styling for issue #66 */}
      <img src={RulotechLogo} alt="" className={classes.rulotechlogo} />
      <form className={classes.formStyles} noValidate autoComplete="off">
        <button
          type="button"
          className={classes.backToLogin}
          onClick={() => setLogType("logIn")}
        >
          <img
            src={arrowLeft}
            alt=""
            style={{
              margin: "0 1rem 0 0",
            }}
          />
          Back to login
        </button>
        <div>
          Please provide your registered email, in which a verification code
          will be provided to reset your password.
        </div>
        <span>
          <div className={classes.inputName}>
            <span>
              Email <span style={{ color: "#ED254E" }}>*</span>
            </span>
            <span className={classes.warnings}>
              {!emailValid ? "Not a valid email" : null}
            </span>
          </div>
          <TextField
            className={clsx({
              [classes.valid]: emailValid,
              [classes.invalid]: !emailValid,
            })}
            classes={{
              root: classes.input,
            }}
            value={email}
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setEmail(e.target.value)}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={emailValid ? envelopeIcon : envelopeIconError}
                    alt=""
                    style={{
                      color: !emailValid ? "#ED254E" : "#DDDDDD",
                      margin: "0 1rem 0 0",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </span>
        <div className={classes.resetLinkContainer}>
          <button type="button" className={classes.resetLink} onClick={getCode}>
            Send password reset link
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStyles: {
      display: "flex",
      flexDirection: "column",
      width: "23em",
      minHeight: "25em",
      justifyContent: "space-evenly",
      marginLeft: "30%",
    },
    inputName: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    warnings: {
      fontSize: "12px",
      color: "#ED254E",
      position: "relative",
      top: "5px",
    },
    input: {
      display: "flex",
      justifyContent: "center",
      borderRadius: 0,
      color: "white",
      height: 40,
      width: "95%",
      padding: "0 0 0 5%",
      border: "solid 1px #DDDDDD",
    },
    valid: {
      border: "solid 1px #DDDDDD",
    },
    invalid: {
      border: "solid 1px #ED254D",
    },
    forgotPassword: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: Colors.MainColor, //changed color to slate for issue #66
      fontSize: "14px",
      cursor: "pointer",
      border: "none",
      background: "none",
      "&:hover": {
        opacity: ".8",
      },
      "&:focus": { outline: 0 },
    },
    backToLogin: {
      display: "flex",
      flexDirection: "row",
      alignContent: "flex-start",
      alignItems: "center",
      padding: 0,
      border: "none",
      background: "none",
      color: Colors.MainColor,//changed color to slate for issue #66
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": {
        opacity: ".8",
      },
      "&:focus": { outline: 0 },
    },
    resetLinkContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    resetLink: {
      backgroundColor: Colors.MainColor,//changed color to slate for issue #66
      width: "70%",
      fontSize: "14px",
      fontWeight: 600,
      color: "#fff",
      border: "none",
      borderRadius: 0,
      height: "39px",
      "&:hover": {
        cursor: "pointer",
        opacity: ".8",
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    //Added css class rulotechlogo for styling rulotech logo for issue #66
    rulotechlogo: {
      margin: "0 0 0 7rem",
      width: "193px",
      height: "36px",
      [`@media (max-width: 800px)`]: {
        margin: "0 0 0 7rem",
        width: "50%",
      },
    },
  })
);
