export enum Colors {
  BasinRed = "#253746", //updated color from #6D1919 to #253746 for issue #204
  MainColor = "#253746",
  Red = "#FF0000",
  Pink = "#ED254E",
  LightGray = "#DDDDDD",
  Gray = "#A6A6A6",
  Green = "#00D160",
  White = "#FFFFFF",
  Yellow = "#FFC529",
  Black = "#000",
  BasinBlue = "#56CCF2",
  BasinDarkBlue = "#067BC2",
  BasinPurple = "#BB6BD9",
  Orange = "#FF6900",
  DashboardRed = "#ed254e",
  DashboardYellow = "#fcc42c",
  DashboardGreen = "#00D160",
  DashboardBlue = "#047cc4",
  DashboardDarkBlue = "#002E46",
  DefaultTheme = "#002E46",
  LightRed = "#ed254e"
}
