/*Issue fixed for issue #66 */
import React, { useState, useEffect } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import PasswordValidation from "utils/validations/PasswordValidation";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import RulotechLogo from "assets/bt-logins/rulo_logo_2.svg";//imported rulotech logo for issue #66
import { setForgottenEmail } from "app/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import passwordKey from "assets/bt-logins/login_password.svg";
import passwordKeyError from "assets/bt-logins/login_password_red.svg";
import { Auth } from "aws-amplify";
import clsx from "clsx";
import arrowLeft from "assets/bt-logins/arrow-left-circle.svg";
import { Colors } from "common/Colors";

type SetNewPasswordProps = {
  setLogType: Function;
  onStateChange?: (state: string, data?: any) => void;
};

const SetNewPassword: React.FC<SetNewPasswordProps> = ({ setLogType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeValid, setIsVerificationCodeValid] = useState(true);
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  const { currentForgottenEmail } = useSelector(
    (state: RootState) => state.AppSlice
  );

  const checkPasswordValid = (validPassword: boolean) => {
    setIsPasswordValid(validPassword);
  };

  const resetPassword = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (verificationCode.length !== 6 && !password) {
      setIsVerificationCodeValid(false);
      return;
    }

    try {
      await Auth.forgotPasswordSubmit(
        currentForgottenEmail,
        verificationCode,
        password
      );

      setResetSuccess(true);
      dispatch(setForgottenEmail(""));
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  useEffect(() => {
    if (verificationCode.length === 6) {
      setIsVerificationCodeValid(true);
    }
  }, [verificationCode]);

  return (
    <div>
      {/* Changed img src to RulotechLogo and added css class rulotechlogo for styling for issue #66 */}
      <img src={RulotechLogo} alt="" className={classes.rulotechlogo} />
      {!resetSuccess ? (
        <>
          <form className={classes.formStyles} noValidate autoComplete="off">
            <button
              type="button"
              className={classes.backToLogin}
              onClick={() => setLogType("logIn")}
            >
              <img
                src={arrowLeft}
                alt=""
                style={{
                  margin: "0 1rem 0 0",
                }}
              />
              Back to login
            </button>
            <div>Reset code has been sent.</div>
            <div>
              Please, enter the code that was sent to {currentForgottenEmail}.
            </div>
            <div className={classes.inputField}>
              <div className={classes.inputName}>
                <span>
                  Verification code <span style={{ color: "#ED254E" }}>*</span>
                </span>
                <span className={classes.warnings}>
                  {!verificationCodeValid
                    ? "Verification code not valid"
                    : null}
                </span>
              </div>
              <TextField
                className={clsx({
                  [classes.valid]: verificationCodeValid,
                  [classes.invalid]: !verificationCodeValid,
                })}
                classes={{
                  root: classes.input,
                }}
                value={verificationCode}
                placeholder="Enter verification code"
                onChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setVerificationCode(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className={classes.inputField}>
              <div className={classes.inputName}>
                <span>
                  New password <span style={{ color: "#ED254E" }}>*</span>
                </span>
              </div>
              <TextField
                className={clsx({
                  [classes.valid]: isPasswordValid,
                  [classes.invalid]: !isPasswordValid,
                })}
                type="password"
                classes={{
                  root: classes.input,
                }}
                value={password}
                onChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setPassword(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={!isPasswordValid ? passwordKeyError : passwordKey}
                        alt=""
                        style={{
                          margin: "0 1rem 0 0",
                          width: "60%",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <PasswordValidation
                password={password}
                setIsPasswordValid={checkPasswordValid}
              />
            </div>
            <div className={classes.buttonContainer}>
              <button
                type="button"
                className={classes.proceedBtn}
                // ! here once validations check, proceed to next sign up
                onClick={(e) => {
                  resetPassword(e);
                }}
              >
                Create new password
              </button>
            </div>
          </form>{" "}
        </>
      ) : (
        <>
          <form className={classes.formStyles} noValidate autoComplete="off">
            <div className={classes.inputField}>Reset successful!</div>
            <div className={classes.inputField}>
              You are now able to sign in with your new password.
            </div>
            <div className={classes.buttonContainer}>
              <button
                type="button"
                className={classes.proceedBtn}
                onClick={() => {
                  setLogType("logIn");
                }}
              >
                Return to log in
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default SetNewPassword;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStyles: {
      display: "flex",
      flexDirection: "column",
      minWidth: "23rem",
      maxWidth: "30rem",
      height: "100%",
      justifyContent: "center",
      marginLeft: "30%",
    },
    createAccount: {
      fontSize: "24px",
      margin: "2rem 0",
    },
    inputName: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    inputField: { margin: "1rem 0 0 0" },
    warnings: {
      fontSize: "12px",
      color: "#ED254E",
      position: "relative",
      top: "5px",
    },
    input: {
      display: "flex",
      justifyContent: "center",
      borderRadius: 0,
      color: "white",
      height: 40,
      width: "95%",
      padding: "0 0 0 5%",
      border: "solid 1px #DDDDDD",
    },
    valid: {
      border: "solid 1px #DDDDDD",
    },
    invalid: {
      border: "solid 1px #ED254D",
    },
    forgotPassword: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: Colors.MainColor,//changed color to slate for issue #66
      fontSize: "14px",
      cursor: "pointer",
      border: "none",
      background: "none",
      "&:hover": {
        opacity: ".8",
      },
      "&:focus": { outline: 0 },
    },
    backToLogin: {
      display: "flex",
      flexDirection: "row",
      alignContent: "flex-start",
      alignItems: "center",
      margin: "0.7rem 0 1rem 0",//changed top margin for issue #66
      padding: 0,
      border: "none",
      background: "none",
      color: Colors.MainColor,//changed color to slate for issue #66
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": {
        opacity: ".8",
      },
      "&:focus": { outline: 0 },
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    proceedBtn: {
      backgroundColor: Colors.MainColor,//changed color to slate for issue #66
      width: "50%",
      fontSize: "14px",
      fontWeight: 600,
      color: "#fff",
      border: "none",
      borderRadius: 0,
      height: "39px",
      "&:hover": {
        cursor: "pointer",
        opacity: ".8",
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    //Added css class rulotechlogo for styling rulotech logo for issue #66
    rulotechlogo: {
      margin: "0 0 0 8.3rem",
      width: "193px",
      height: "36px",
      [`@media (max-width: 800px)`]: {
        margin: "0 0 0 7rem",
        width: "50%",
      },
    },
  })
);
