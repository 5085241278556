/* Issue fixed for github issue #19, issue #24, issue #44, issue #56, issue #83, issue #82,issue #133,issue #140*/
import React, { useState, useCallback, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import {
  BarChart as SingleBarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
// import { Colors } from "common/Colors";
import LinearProgress from "@material-ui/core/LinearProgress";
import GraphicEqIcon from "@material-ui/icons/GraphicEq";
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";
import {
  handleActiveHourBar,
  handleActiveOverHourBar,
  handleActiveOverADay,
  handleActiveOverWeekBar
} from "utils/connectivityBarHandler";
import { BarInformationProps } from "components/AssetStatusCard/types";
import { Colors } from "common/Colors";
import GoogleLinkButton from "components/common-buttons/GoogleLinkButton";
// import YellowLogo from "assets/bt-equipments/ic_pump_y_big.svg";
import { binaryFromJsonList } from "utils/binaryToText";
import { numberToTextKey } from "utils/constants";
import { binaryToTextKey } from "utils/constants";
import { getDecodedVal } from "utils/conversions";
import { format, fromUnixTime } from "date-fns"; //imported for issue #133
import { useMediaQuery } from "react-responsive"; //for issue #82
import BarChart from "components/asset-charts/BarChart"; //imported for issue #133
import BarChart2 from "components/asset-charts/BarChart2"; //imported for issue #133
import IndicatorChart from "components/asset-charts/IndicatorChart"; //imported for issue #133
import { ParameterProps } from "features/real-time-status-page/types"; //imported for issue #133
import GaugeChart from "components/asset-charts/GaugeChart"; //imported for issue #133
import DonutChart from "components/asset-charts/DonutChart"; //imported for issue #133
import { MdChevronLeft, MdChevronRight } from "react-icons/md"; //imported arrow icons for issue #133

type AssetReadinessCardProp = {
  asset: any;
  handleAsset?: Function;
  isMarker?: boolean;
  index: any; //Added index props for issue #82
  // handleAsset?: (
  //   assetName: string,
  //   assetId: string,
  //   complex_asset: boolean
  // ) => void;
};

const AssetReadinessCard: React.FC<AssetReadinessCardProp> = ({
  asset,
  handleAsset,
  isMarker,
  index //Added index props for issue #82
}) => {
  const classes = useStyles();
  const [markerLocation, setMarkerLocation] = useState([]);
  const [assetInformation, setAssetInformation] = useState<
    BarInformationProps[]
  >([
    {
      name: "",
      lessThanHour: 0,
      overAnHour: 0,
      overADay: 0,
      overAWeek: 0
    }
  ]);
  //Added variable to check which param tile is displayed in complex asset for issue #133
  const [complexParamTileActive, setComplexParamTileActive] = useState(0);

  let tooltip: string;

  //added variable to check if it is mobile view or web view for issue #82
  var ismobileview = useMediaQuery({ query: "(max-width: 800px)" });

  //function for handling arrow click and displaying appropriate tile issue #133
  const handleArrowClick = (arrowType: string) => {
    // checking number of parameters issue #133
    var totalTiles = asset.complex_asset_parameter.length;
    var changeTile = false;
    var slideToTile = -1;
    // if left arrow is clicked for issue #133
    if (arrowType === "left") {
      if (complexParamTileActive > 0) {
        slideToTile = complexParamTileActive - 1;
        changeTile = true;
      } else {
        slideToTile = totalTiles - 1;
        changeTile = true;
      }
    }
    // if right arrow is clicked for issue #133
    if (arrowType === "right") {
      if (complexParamTileActive <= totalTiles - 2) {
        slideToTile = complexParamTileActive + 1;
        changeTile = true;
      } else {
        slideToTile = 0;
        changeTile = true;
      }
    }
    // if we have clicked any arrow then setting the variable to that tile to display for issue #133
    if (changeTile) {
      setComplexParamTileActive(slideToTile);
    }
  };

  //Added function to display the type of chart for issue #133
  const renderChartByDisplayType = (parameter: ParameterProps) => {
    switch (parameter.display_type) {
      case "Horizontal bar":
        return <BarChart2 params={parameter} />;
      // Updated the spelling for vertical, Github issue #57
      case "Vertical bar":
        return <BarChart params={parameter} />;
      case "Doughnut":
        return <DonutChart params={parameter} />;
      case "Gauge":
        return <GaugeChart params={parameter} />;
      case "Indicator":
        return <IndicatorChart params={parameter} />;
      default:
        break;
    }
  };

  // changing the html to display complex asset same as group level for issue #133
  const renderAssetInformation = (assetParam: any) => {
    if (JSON.stringify(assetParam) === "{}") {
      return;
    }

    return (
      <div>
        {/* using same html as group level for issue #133 */}
        {assetParam?.map((asset_parameter: any, index: number) => {
          return (
            // div to display the charts accordingly the styles for issue #133
            index == complexParamTileActive && (
              // Adding css class based on parameter display type for issue #140
              <div
                className={
                  asset_parameter?.display_type.toLowerCase() == "none"
                    ? classes.pieChartGridNone
                    : asset_parameter?.display_type.toLowerCase() ==
                      "horizontal bar"
                      ? classes.pieChartGridHorizontal
                      : asset_parameter?.display_type.toLowerCase() == "gauge"
                        ? classes.pieChartGridGauge
                        : asset_parameter?.display_type.toLowerCase() ==
                          "vertical bar"
                          ? classes.pieChartGridVertical
                          : asset_parameter?.display_type.toLowerCase() == "indicator"
                            ? classes.pieChartGridIndicator
                            : asset_parameter?.display_type.toLowerCase() == "doughnut"
                              ? classes.pieChartGridDonut
                              : classes.pieChartGrid
                }
              >
                <div
                  className={
                    asset_parameter === undefined ||
                      asset_parameter?.display_type.toLowerCase() == "none"
                      ? classes.chartDetailsNone
                      : asset_parameter?.display_type.toLowerCase() ==
                        "horizontal bar"
                        ? classes.chartDetailsHorizontal
                        : asset_parameter?.display_type.toLowerCase() ==
                          "vertical bar"
                          ? classes.chartDetailsVertical
                          : asset_parameter?.display_type.toLowerCase() ==
                            "doughnut"
                            ? classes.chartDetailsDonut
                            : classes.chartDetails
                  }
                >
                  {/* Added condition for checking the asset_parameter for the map broken issue. Github issue #45 */}
                  {asset_parameter != undefined ? (
                    // Added condition for styling left hand side and right hand side tiles for issue #82
                    <div
                      className={
                        asset_parameter?.display_type.toLowerCase() == "none"
                          ? classes.innerContainerNone
                          : asset_parameter?.display_type.toLowerCase() ==
                            "vertical bar" ||
                            asset_parameter?.display_type.toLowerCase() ==
                            "horizontal bar"
                            ? classes.innerContainerVertical
                            : classes.innerContainer
                      }
                    >
                      {/* Calling function to display the charts same as group level for issue #133 */}
                      {renderChartByDisplayType(asset_parameter)}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* Added a separate css class for the horizontal text alginment and the none type test alignment. Github issue #82 */}
                {/* // Added condition for styling left hand side and right hand side tiles for issue #82 */}
                <div
                  className={
                    asset_parameter?.display_type.toLowerCase() == "doughnut" ||
                      asset_parameter?.display_type.toLowerCase() == "gauge"
                      ? classes.textContainer
                      : asset_parameter?.display_type.toLowerCase() ==
                        "vertical bar"
                        ? classes.textContainerVertical
                        : asset_parameter?.display_type.toLowerCase() == "none"
                          ? classes.textContainerNone
                          : classes.textContainerHorizontal
                  }
                >
                  <div>
                    <p
                      className={classes.regularText}
                      style={{ fontWeight: 600 }}
                    >
                      {asset_parameter?.parameter_name}
                    </p>
                    {/* Added condition for checking the asset_parameter for the map broken issue. Github issue #45 */}
                    {asset_parameter != undefined ? (
                      <p className={classes.regularText}>
                        {/* Added the function for the conversion of feet, hours , minutes, binary to text and number to text. Github issue #44 */}
                        {getDecodedVal(
                          asset_parameter,
                          Number(asset_parameter?.parameter_value),
                          numberToTextKey,
                          binaryToTextKey,
                          binaryFromJsonList
                        )}{" "}
                        {asset_parameter?.unit}
                      </p>
                    ) : (
                      "NaN"
                    )}

                    <span>
                      {/* Added the condition for showing if the asset is in the running state for issue #133 */}
                      {asset_parameter?.device_ready ? (
                        <p className={classes.regularText}>
                          Running
                          {/* Added the condition for showing the error and warning if any  for issue #133 */}
                          {asset_parameter.device_warning != null ? (
                            asset_parameter.device_warning ? (
                              /* Added the condition for showing the warning count if any for issue #133 */
                              <span className={classes.warningText}>
                                {/* changes made for color of | symbol for issue #19  */}{" "}
                                <span style={{ color: "black" }}>|</span>{" "}
                                Warning
                              </span>
                            ) : (
                              /* Added the condition for showing the error if any for issue #133 */
                              <span className={classes.errorText}>
                                {/* changes made for color of | symbol for issue #19  */}{" "}
                                <span style={{ color: "black" }}>|</span>{" "}
                                Critical
                              </span>
                            )
                          ) : (
                            ""
                          )}
                        </p>
                      ) : (
                        <p className={classes.errorText}>
                          Stopped
                          {/* Added the condition for showing the error and warning if any for issue #133 */}
                          {asset_parameter.device_warning != null ? (
                            asset_parameter.device_warning ? (
                              /* Added the condition for showing the warning if any for issue #133 */
                              <span className={classes.warningText}>
                                {/* changes made for color of | symbol for issue #19  */}{" "}
                                <span style={{ color: "black" }}>|</span>{" "}
                                Warning
                              </span>
                            ) : (
                              /* Added the condition for showing the error if any for issue #133 */
                              <span className={classes.errorText}>
                                {/* changes made for color of | symbol for issue #19  */}{" "}
                                <span style={{ color: "black" }}>|</span>{" "}
                                Critical
                              </span>
                            )
                          ) : (
                            ""
                          )}
                        </p>
                      )}
                    </span>
                    {/* Added new css class for last read text for issue #140 */}
                    <p className={classes.lastReadText}>
                      {asset_parameter?.last_read
                        ? format(
                          fromUnixTime(asset_parameter?.last_read),
                          //changed from mmmm dd,yyyy to MM/dd/yyyy for issue #24
                          "MM/dd/yyyy | h:mm a"
                        )
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
            )
          );
        })}
      </div>
    );
  };

  const CustomTooltip = ({ active, payload }: any) => {
    let displayDiv;
    let color;

    if (!active || !tooltip) return null;
    for (const bar of payload) {
      if (bar.dataKey === tooltip) {
        switch (bar.name) {
          case "lessThanHour":
            displayDiv = `${bar.value} asset(s) communicated within the hour.`;
            color = bar.color;
            break;
          case "overAnHour":
            displayDiv = `${bar.value} asset(s) did not communicate within the last hour.`;
            color = bar.color;
            break;
          case "overADay":
            displayDiv = `${bar.value} asset(s) did not communicate in over 24 hours.`;
            color = bar.color;
            break;
          case "overAWeek":
            displayDiv = `${bar.value} asset(s) did not communicate over the last week.`;
            color = bar.color;
            break;
          default:
            break;
        }
      }
    }

    return (
      <div className={classes.tooltip} style={{ background: color }}>
        {displayDiv}
      </div>
    );
  };

  const handleAssetInformation = useCallback(() => {
    const {
      devices_active,
      devices_active_over_day,
      devices_active_over_hour,
      devices_active_over_week
    } = asset.active_counts;

    setAssetInformation([
      {
        name: "Asset information",
        lessThanHour: devices_active,
        overAnHour: devices_active_over_hour,
        overADay: devices_active_over_day,
        overAWeek: devices_active_over_week
      }
    ]);
  }, [asset.active_counts]);

  useEffect(() => {
    if (asset) {
      handleAssetInformation();
    }
  }, [asset, handleAssetInformation]);

  useEffect(() => {
    if (asset) {
      setMarkerLocation(asset.gio_location);
    }
  }, [asset]);

  return (
    <div className={classes.root}>
      {/* Added css class complexAssetOuterLayout for styling the grid layout for complex asset for issue #140 */}
      <div className={classes.complexAssetOuterLayout}>
        {/* Added condition to give respective styling to odd even class for issue #82 */}
        <div className={classes.headerInfo}>
          <GraphicEqIcon style={{ fontSize: "30" }} />
        </div>
        {/* Added div with css style to align asset name with chart or text for issue #140 */}
        <div style={{ gridArea: "two", display: "flex", alignItems: "center" }}>
          <p
            className={classes.headerID}
            style={handleAsset ? {} : { cursor: "default" }}
            onClick={() => {
              const { asset_id = "", asset_name = "", complex_asset = false } =
                asset || {};
              handleAsset && handleAsset(asset_name, asset_id, complex_asset);
            }}
          >
            {asset.asset_name}
          </p>
          <Chip
            size="small"
            label={asset.asset_category_name}
            style={{ marginLeft: "1em" }}
          />
        </div>
        {/* Added div with left arrow to align it with the icon for issue #140 */}
        <div
          style={{ gridArea: "three", display: "flex", alignItems: "center" }}
        >
          {/* Adding left arrow with its onclick function for issue #133 */}
          <MdChevronLeft
            size={25} //increased the icon size for issue #140
            style={{
              cursor: "pointer",
              zIndex: 100,
              color: Colors.DashboardBlue
            }}
            onClick={() => {
              handleArrowClick("left");
            }}
          />
        </div>
        {/* Added div with style to align chart starting with the asset name for issue #140 */}
        <div style={{ gridArea: "four" }}>
          {renderAssetInformation(asset.complex_asset_parameter)}
        </div>
        {/* Added div with style for right arrow for issue #140 */}
        <div
          style={{ gridArea: "five", display: "flex", alignItems: "center" }}
        >
          {/* Adding right arrow with its onclick function for issue #133 */}
          <MdChevronRight
            size={25} //increased size of arrow for issue #140
            style={{
              cursor: "pointer",
              zIndex: 100,
              color: Colors.DashboardBlue
            }}
            onClick={() => {
              handleArrowClick("right");
            }}
          />
        </div>
        <div style={{ gridArea: "six" }}>
          {/* Changed from span to div for issue #82 */}
          <div
            // removing "styles" and adding connectivitybar class for styling conncetivity bar in responsive UI issue #24
            className={classes.connectivitybar}
          >
            {/* Added css class settingsIcon for icon styling for issue #82 */}
            <SettingsInputAntennaIcon className={classes.settingsIcon} />
            {/* Added condition to decide comms bar length in mobile view and web view for issue #82 */}
            <ResponsiveContainer height={15} width={"100%"}>
              <SingleBarChart
                layout="vertical"
                data={assetInformation}
                margin={{ left: 6 }}
                stackOffset="expand"
              >
                <XAxis hide type="number" />
                <YAxis hide type="category" dataKey="name" stroke="#FFFFFF" />
                <Tooltip cursor={false} content={<CustomTooltip />} />
                <Bar
                  dataKey="lessThanHour"
                  fill={Colors.BasinBlue}
                  stackId="a"
                  radius={handleActiveHourBar(assetInformation[0])}
                  name="lessThanHour"
                  onMouseOver={() => (tooltip = "lessThanHour")}
                />
                <Bar
                  dataKey="overAnHour"
                  fill={Colors.BasinDarkBlue}
                  stackId="a"
                  radius={handleActiveOverHourBar(assetInformation[0])}
                  name="overAnHour"
                  onMouseOver={() => (tooltip = "overAnHour")}
                />
                <Bar
                  dataKey="overADay"
                  fill={Colors.BasinPurple}
                  stackId="a"
                  radius={handleActiveOverADay(assetInformation[0])}
                  name="overADay"
                  onMouseOver={() => (tooltip = "overADay")}
                />
                <Bar
                  dataKey="overAWeek"
                  fill={Colors.Gray}
                  stackId="a"
                  radius={handleActiveOverWeekBar(assetInformation[0])}
                  name="overAWeek"
                  onMouseOver={() => (tooltip = "overAWeek")}
                />
              </SingleBarChart>
            </ResponsiveContainer>
          </div>
          {isMarker && (
            <GoogleLinkButton
              latitude={markerLocation[1]}
              longitude={markerLocation[0]}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default AssetReadinessCard;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column"
    },
    iconReady: {
      height: "2em"
    },
    iconStopped: {
      height: "2em"
    },
    headerInfo: {
      gridArea: "one", //added grid area style to align it with starting of 1st notification tile for issue #140
      marginRight: "1em", //added marginRight to have some space between asset icon and asset name for issue #140
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontWeight: 600,
      // Added media query for styling in mobile view of folder text for issue #82
      [`@media (max-width: 800px)`]: {
        // marginLeft: "5% !important"
      },
      [`@media (min-width: 1920px) and (max-width: 2400px)`]: {
        // marginLeft: "-2% !important",
        // marginBottom: "2% !important"
      },
      [`@media (min-width: 2560px) and (max-width: 3000px)`]: {
        // marginLeft: "-1% !important",
        // marginBottom: "2% !important"
      }
    },
    textBlocksContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "1em",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      alignContent: "flex-start"
    },
    textContainer: {
      margin: "auto 0" // added margin for issue #140
    },
    textPrimary: {
      padding: 0,
      margin: 0
    },
    textSecondary: {
      padding: 0,
      margin: 0,
      fontSize: ".8em"
    },
    headerID: {
      //added styles for aligning asset name for issue #140
      height: "3em",
      display: "flex",
      alignItems: "center",
      margin: "0",
      color: `${theme.palette.primary.light}`,
      "&:hover": {
        cursor: "pointer"
      }
    },
    tooltip: {
      width: 150,
      height: 60,
      padding: 10,
      color: Colors.White,
      fontSize: 11
    },

    //removed styles tab and added css class for issue #82
    settingsIcon: {
      fontSize: "1em"
    },

    //adding css class with media query in place of "styles" for styling connectivity bar in responsive UI issue #24
    connectivitybar: {
      display: "flex",
      flexDirection: "row"
    },

    // Adding all the below css classes for styling the complex asset same as group level for issue #133

    //Added css class for gauge styling for issue #82
    chartDetails: {},
    //Added this css class to show the horizontal bar. Github issue #55
    chartDetailsHorizontal: {
      marginRight: "1rem" //Added margin to add some space between chart and text for issue #140
    },
    //Added this css class to show the vertical bar for issue #88
    chartDetailsVertical: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    //Added this css class to show the donut for issue #82
    chartDetailsDonut: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    //Added this css class to show the text properly if display type is none. Github issue #55
    chartDetailsNone: {},
    errorText: {
      color: "#d61834",
      marginBottom: ".1em",
      marginTop: 0,
      // Added media queries to make the text box approx same height as graph for issue #140
      [`@media (min-width: 1800px) and (max-width: 2100px)`]: {
        fontSize: "16px"
      },
      [`@media (min-width: 2101px) and (max-width: 2500px)`]: {
        fontSize: "20px"
      },
      [`@media (min-width: 2501px)`]: {
        fontSize: "24px"
      }
    },
    warningText: {
      color: "#fcc42c",
      marginBottom: ".1em",
      marginTop: 0,
      // Added media queries to make the text box approx same height as graph for issue #140
      [`@media (min-width: 1800px) and (max-width: 2100px)`]: {
        fontSize: "16px"
      },
      [`@media (min-width: 2101px) and (max-width: 2500px)`]: {
        fontSize: "20px"
      },
      [`@media (min-width: 2501px)`]: {
        fontSize: "24px"
      }
    },
    regularText: {
      marginBottom: ".1em",
      marginTop: 0,
      // Added media queries to make the text box approx same height as graph for issue #140
      [`@media (min-width: 1800px) and (max-width: 2100px)`]: {
        fontSize: "16px"
      },
      [`@media (min-width: 2101px) and (max-width: 2500px)`]: {
        fontSize: "20px"
      },
      [`@media (min-width: 2501px)`]: {
        fontSize: "24px"
      }
    },
    // Added new css class with media queries to keep font size of same height as graph for issue #140
    lastReadText: {
      marginBottom: ".1em",
      marginTop: 0,
      fontSize: "9.8px",
      [`@media (min-width: 1800px) and (max-width: 2100px)`]: {
        fontSize: "11.2px"
      },
      [`@media (min-width: 2101px) and (max-width: 2500px)`]: {
        fontSize: "14px"
      },
      [`@media (min-width: 2501px)`]: {
        fontSize: "16.8px"
      }
    },
    //Added media queries for word wrap. Github issue #82
    //Added a separate class for vertical display type for word wrap. Github issue #82
    textContainerVertical: {
      margin: "auto 0"
    },
    //Added a separate class for horizontal and none display type for word wrap. Github issue #82
    textContainerHorizontal: {
      margin: "auto 0"
    },
    textContainerNone: {
      height: "max(125px,20vh)", //added height so that text container none tile can be horizontally aligned with other tiles for issue #140
      display: "flex",
      alignItems: "center"
    },
    innerContainer: {
      minWidth: "100%" //added minwidth so that chart takes up the allocated grid space for issue #140
    },
    //added new css class for indicator chart for issue #140
    innerContainerindicator: {
      display: "flex",
      justifyContent: "center"
    },
    //Added this css class to show the text properly if display type is none. Github issue #55
    innerContainerNone: {},
    //Added this css class to show the Chart algined properly if display type is vertical bar. Github issue #82
    innerContainerVertical: {
      minWidth: "100%" //added minwidth so that chart takes up the allocated grid space for issue #140
    },
    // Added css class for styling and allocating grid layout for issue #140
    complexAssetOuterLayout: {
      display: "grid",
      gridTemplateAreas: '"one two ." "three four five" ". six six"', //added grid areas to align the arrows,chart,text,header,asset name and connectivity bar for issue #140
      gridTemplateColumns: "auto minmax(0,1fr)"
    },
    //Added piechart grid to align charts and text for issue #140
    pieChartGrid: {
      display: "grid",
      gridTemplateColumns: "1fr 2fr",
      margin: "1rem 0"
    },
    //Added piechart grid to align text with no chart for issue #140
    pieChartGridNone: {
      display: "grid",
      gridTemplateColumns: "1fr",
      margin: "1rem 0"
    },
    //Added piechart grid to align horizontal chart and text for issue #140
    pieChartGridHorizontal: {
      display: "grid",
      gridTemplateColumns: "minmax(0,1.8fr) minmax(0,1.2fr)",
      margin: "1rem 0"
    },
    //Added piechart grid to align vertical chart and text for issue #140
    pieChartGridVertical: {
      display: "grid",
      gridTemplateColumns: "minmax(0,1.8fr) minmax(0,1.2fr)",
      margin: "1rem 0"
    },
    //Added piechart grid to align gauge chart and text for issue #140
    pieChartGridGauge: {
      display: "grid",
      gridTemplateColumns: "minmax(0,1.8fr) minmax(0,1.2fr)",
      margin: "1rem 0"
    },
    //Added piechart grid to align indicator chart and text for issue #140
    pieChartGridIndicator: {
      display: "grid",
      gridTemplateColumns: "minmax(0,1.8fr) minmax(0,1.2fr)",
      margin: "1rem 0"
    },
    //Added piechart grid to align donut chart and text for issue #140
    pieChartGridDonut: {
      display: "grid",
      gridTemplateColumns: "minmax(0,1.8fr) minmax(0,1.2fr)",
      margin: "1rem 0"
    }
  })
);
