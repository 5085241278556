import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { setAssetDeviceList } from "../assetsDashboardSlice";
import { useForm, Controller } from "react-hook-form";
import { InputLabel } from "components/input-label/InputLabel";
import { devicesAPI } from "services/devicesAPI";
import { Colors } from "common/Colors";
import { RootState } from "app/rootReducer";
import { RequiredDeviceProps } from "./types";
// import clsx from "clsx";

type AddDeviceModalProps = {
  open: boolean;
  handleClose: Function;
};

export const AddDeviceModal: React.FC<AddDeviceModalProps> = ({
  open,
  handleClose,
  //   handleGroupModals,
  //   nodeObject,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [alarmsNotificationsChecked, setalarmsNotificationsChecked] =
    useState(false);
  const [selectedDevice, setSelectedDevice] = useState({
    label: "",
    value: "",
  });
  const { register, handleSubmit, control, errors } =
    useForm<RequiredDeviceProps>();

  const { deviceList, currentAssetId } = useSelector(
    (state: RootState) => state.AssetDashboardSlice
  );

  const handleCloseDeviceModal = () => {
    handleClose();
    setalarmsNotificationsChecked(false);
  };

  const handleDeviceSave = async ({ device }: RequiredDeviceProps) => {
    try {
      const updatedDeviceList = await devicesAPI.addDeviceToAsset(
        currentAssetId,
        device.value,
        alarmsNotificationsChecked
      );
      //! this response might needed to be mapped to {label, value} object
      // console.log(updatedDeviceList);
      dispatch(setAssetDeviceList(updatedDeviceList));
      handleCloseDeviceModal();
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription("Device successfully added!"));
    } catch (error) {
      alert(error);
    }
  };

  const handleShowAlarmsAndNotifications = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setalarmsNotificationsChecked(e.target.checked);
  };

  useEffect(() => {
    setSelectedDevice(deviceList[0]);
  }, [deviceList]);

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalOverlay}>
          <form onSubmit={handleSubmit(handleDeviceSave)}>
            <div className={classes.modalBody}>
              <div className={classes.header}>
                <p className={classes.headerText}>Add new device</p>
                <CloseIcon
                  className={classes.icon}
                  onClick={handleCloseDeviceModal}
                />
              </div>
              <div>
                <div className={classes.pathText}>
                  <p style={{ fontWeight: 600, color: Colors.Gray }}>Path: </p>
                </div>
                <div className={classes.inputGroup}>
                  <InputLabel label="Device" name="device" errors={errors} />
                  <Controller
                    render={(props) => {
                      return (
                        <Select
                          {...props}
                          styles={reactSelectStyles}
                          defaultValue={{
                            label: "Select a device ...",
                            value: "",
                          }}
                          options={deviceList}
                          maxMenuHeight={220}
                          classNamePrefix="select"
                          isSearchable
                          inputRef={props.ref}
                        />
                      );
                    }}
                    // options={deviceList}
                    name="device"
                    control={control}
                    rules={{
                      required: !deviceList.length
                        ? "No available devices."
                        : "Please select a device to attach.",
                    }}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        classes={{
                          label: classes.label,
                          root: classes.formRoot,
                        }}
                        control={
                          <Checkbox
                            checked={alarmsNotificationsChecked}
                            onChange={handleShowAlarmsAndNotifications}
                            name="summary"
                            size="medium"
                            className={classes.cb}
                          />
                        }
                        label="Show events and notifications"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
              <div className={classes.actionItems}>
                <button
                  className={classes.cancelButton}
                  onClick={handleCloseDeviceModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={classes.saveButton}
                // onClick={handleDeviceSave}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddDeviceModal;

const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer",
  }),
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0 1rem",
    },
    cb: {
      color: theme.palette.primary.light,
      backgroundColor: "transparent",
    },
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)",
    },
    modalBody: {
      maxHeight: "90vh",
      overflowY: "auto",
      width: 500,
      backgroundColor: Colors.White,
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 2.5rem",
      margin: "0 0 1em 0",
      borderBottom: `1px solid ${Colors.LightGray}`,
    },
    headerText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0,
    },
    icon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem",
    },
    reactSelect: {
      borderRadius: 0,
    },
    pathText: {
      backgroundColor: Colors.LightGray,
      display: "flex",
      padding: ".5rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        marginRight: "1rem",
        fontSize: "1rem",
      },
    },
    labelText: {
      display: "flex",
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        marginRight: "1rem",
        fontSize: "1rem",
      },
    },
    label: {
      fontSize: "16px",
      color: theme.palette.primary.light,
    },
    formRoot: {
      height: 25,
    },
    inputGroup: {
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem",
      },
      "& input": {
        width: "100%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
      },
      "& select": {
        width: "100%",
        padding: "0 1rem",
        border: `1px solid ${Colors.LightGray}`,
        height: 38,
        cursor: "pointer",
      },
    },
    innerInput: {
      padding: "1rem 0",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem",
      },
      "& input": {
        width: "100%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
      },
      "& select": {
        width: "100%",
        padding: "0 1rem",
        border: `1px solid ${Colors.LightGray}`,
        height: 38,
        cursor: "pointer",
      },
    },
    actionItems: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "1rem 2.5rem",
      borderTop: `1px solid ${Colors.LightGray}`,
      padding: "1rem 0",
    },
    cancelButton: {
      padding: ".7rem 1.5rem",
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      cursor: "pointer",
      marginRight: "1.5rem",
      fontWeight: "bold",
      backgroundColor: Colors.White,
    },
    saveButton: {
      padding: ".7rem 1.5rem",
      color: Colors.White,
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
      fontWeight: "bold",
      border: "none",
    },
  })
);
