/* Issue fixed for github issue #119*/
import React, { useState } from "react";
import { useStyles } from "./styles";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from "@material-ui/core";
import SignalWifi4BarIcon from "@material-ui/icons/SignalWifi4Bar";
import SignalWifi2BarIcon from "@material-ui/icons/SignalWifi2Bar";
import SignalWifi1BarIcon from "@material-ui/icons/SignalWifi1Bar";

import Battery30Icon from "@material-ui/icons/Battery30";
import Battery60Icon from "@material-ui/icons/Battery60";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";

import BatteryCharging30Icon from "@material-ui/icons/BatteryCharging30";
import BatteryCharging60Icon from "@material-ui/icons/BatteryCharging60";
import BatteryChargingFullIcon from "@material-ui/icons/BatteryChargingFull";

import { Colors } from "common/Colors";
import { useTheme } from "@material-ui/core/styles";
import { DevicePopOver } from "./DevicesPopOver";
import SuccessDeviceModal from "components/success-modal/SuccessDeviceModal";
import { Link, useRouteMatch } from "react-router-dom";
import { fromUnixTime, format, differenceInSeconds, addMonths } from "date-fns";
import Plugged from "assets/bt-assets/Frame-4.png";
import Unplugged from "assets/bt-assets/Frame-1 2.png";
import { getSubscriptionLabel } from "assets/mappings/subscriptionOptions";
import { customDateFormatUnix } from "utils/helpers";
// import LoadSpinner from "common/LoadSpinner";
import clsx from "clsx";

export interface DevicesTableProps {
  deviceList: any[];
}

const DevicesTable: React.FC<DevicesTableProps> = ({ deviceList }) => {
  const styles = useStyles();
  const theme = useTheme();
  const { path } = useRouteMatch();
  const [deviceId, setDeviceId] = useState("");

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleElypsisClick = (event: any, deviceId: string) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setDeviceId(deviceId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const displaySignalStrength = (signalStrength: number[]) => {
    if (!signalStrength) {
      return;
    }
    const [strengthNumber, signalRange] = signalStrength;

    const signalIcon = (signalRange: number) => {
      switch (signalRange) {
        case 0:
          return (
            <SignalWifi1BarIcon
              style={{ color: Colors.Red, height: 16, width: 16 }}
            />
          );
        case 1:
          return (
            <SignalWifi2BarIcon
              style={{ color: Colors.Green, height: 16, width: 16 }}
            />
          );
        case 2:
          return (
            <SignalWifi4BarIcon
              style={{ color: Colors.Green, height: 16, width: 16 }}
            />
          );
        default:
          break;
      }
    };

    return (
      <div className={styles.flex}>
        {signalIcon(signalRange)}
        <span style={{ paddingTop: 5, marginLeft: 5 }}>
          {strengthNumber ? `${strengthNumber} dBm` : ""}
        </span>
      </div>
    );
  };

  const displayPower = (powerInfo: number[]) => {
    const [
      plug,
      externalSupplyVoltage,
      batteryVoltage,
      chargeState
    ] = powerInfo;

    const displayPlug = (plug: number) => {
      if (plug) {
        return (
          <img
            src={Plugged}
            alt="Battery is plugged in"
            style={{ width: 16, height: 16 }}
          />
        );
      } else {
        return (
          <img
            src={Unplugged}
            alt="Battery is not plugged in"
            style={{ width: 16, height: 16 }}
          />
        );
      }
    };

    const displayCharge = (chargeState: number) => {
      switch (chargeState) {
        case 0:
          return (
            <Battery30Icon
              style={{ color: Colors.Red, height: 17, width: 17 }}
            />
          );
        case 1:
          return (
            <BatteryCharging30Icon
              style={{ color: Colors.Red, height: 17, width: 17 }}
            />
          );
        case 2:
          return (
            <Battery60Icon
              style={{ color: Colors.Yellow, height: 17, width: 17 }}
            />
          );
        case 3:
          return (
            <BatteryCharging60Icon
              style={{ color: Colors.Yellow, height: 17, width: 17 }}
            />
          );
        case 4:
          return (
            <BatteryFullIcon
              style={{ color: Colors.Green, height: 17, width: 17 }}
            />
          );
        case 5:
          return (
            <BatteryChargingFullIcon
              style={{ color: Colors.Green, height: 17, width: 17 }}
            />
          );
        default:
          break;
      }
    };

    return (
      <div className={styles.flex}>
        {displayPlug(plug)}
        {displayCharge(chargeState)}
        <span style={{ paddingTop: 5 }}>
          {batteryVoltage >= 0 ? `${batteryVoltage}%` : null}
        </span>
      </div>
    );
  };

  const displayDataUsed = (data: number[]) => {
    const [kbUsed, percentage, colorRange] = data;
    // Added variable to show text for issue #119
    var text = "";
    const getColor = (colorRange: number) => {
      switch (colorRange) {
        case 0:
          // Text to be shown as Normal in black according to the condition for issue #119
          text = "Normal";
          return Colors.Black;
        case 1:
          // Text to be shown as High in yellow according to the condition for issue #119
          text = "High";
          return Colors.Yellow;
        case 2:
          // Text to be shown as Exceeded in red according to the condition for issue #119
          text = "Exceeded";
          return Colors.DashboardRed;
        default:
          text = "";
          break;
      }
    };
    //Added function for fontweight for issue #119
    const getFontWeight = (colorRange: number) => {
      switch (colorRange) {
        case 0:
          return 300;
        case 1:
          return 600;
        case 2:
          return 600;
        default:
          break;
      }
    };

    return (
      <div className={styles.flex}>
        <span
          style={{
            //calling function for text font weight as bold or normal for issue #119
            fontWeight: getFontWeight(colorRange),
            color: getColor(colorRange)
          }}
        >
          {/* Changed text from percentage to show ok,warn,over according to condition for issue #119 */}
          {text}
        </span>
      </div>
    );
  };

  const handleColor = (lastCommunicated: any) => {
    if (lastCommunicated === 0) {
      return Colors.Gray;
    }
    // in seconds
    const hour = 3600;
    const day = 86400;
    const week = 604800;
    let color = Colors.Gray;

    const lastCommunicatedDateObject = new Date(lastCommunicated * 1000);
    const secondsUntilNow = differenceInSeconds(
      new Date(),
      lastCommunicatedDateObject
    );

    if (hour > secondsUntilNow) {
      color = Colors.BasinBlue;
    }
    if (secondsUntilNow > hour && secondsUntilNow < day) {
      color = Colors.BasinDarkBlue;
    }
    if (secondsUntilNow > day && secondsUntilNow < week) {
      color = Colors.BasinPurple;
    }
    if (secondsUntilNow > week) {
      color = Colors.Gray;
    }

    // console.log({ secondsUntilNow });
    return color;
  };

  const handleDateColor = (time: string) => {
    const expUnixTime = Number(time);
    if (isNaN(expUnixTime)) return;

    const expirationDate = fromUnixTime(expUnixTime);
    if (expirationDate < new Date()) {
      // expired
      return Colors.DashboardRed;
    } else if (expirationDate < addMonths(new Date(), 1)) {
      // expiring within 1 month
      return Colors.Yellow;
    }
    // not expiring soon; don't alter color
    return;
  };

  //Added function for date fontweight for issue #119
  const handleFontWeight = (time: string) => {
    const expUnixTime = Number(time);
    if (isNaN(expUnixTime)) return;

    const expirationDate = fromUnixTime(expUnixTime);
    if (expirationDate < new Date()) {
      // expired
      return "bold";
    } else if (expirationDate < addMonths(new Date(), 1)) {
      // expiring within 1 month
      return "bold";
    }
    // not expiring soon; don't alter color
    return;
  };

  return (
    <Grid item xs={12} className={styles.root}>
      {!deviceList.length ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "80vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          No devices to display
        </div>
      ) : (
        <TableContainer style={{ height: "80vh" }}>
          <Table className={styles.table}>
            <TableHead>
              <TableRow>
                {/* Added Model in the device table. Github issue 92 */}
                <TableCell className={styles.header}>Model</TableCell>
                <TableCell className={styles.header}></TableCell>
                <TableCell className={styles.header}>Serial no.</TableCell>
                <TableCell className={styles.header}>Device name</TableCell>
                <TableCell className={styles.header}>
                  Last communication
                </TableCell>
                <TableCell className={styles.header}>Signal</TableCell>
                <TableCell className={styles.header}>Power</TableCell>
                <TableCell className={styles.header}>Cellular data</TableCell>
                <TableCell className={styles.header}>
                  Subscription level
                </TableCell>
                <TableCell className={styles.header}>Valid till</TableCell>
                <TableCell className={styles.header}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deviceList?.map((device: any, i: number) => (
                <TableRow
                  key={i}
                  component={Link}
                  to={`${path}/${device.device_id}`}
                  className={styles.tableRow}
                >
                  {/* Added Model/device_type in the device table. Github issue 92 */}
                  {/* Added condition on device type if child edgetrak device connected or not. Github issue #94 */}
                  <TableCell className={styles.tableCell}>
                    <div className={styles.flex1}>
                      <p className={styles.device}>
                        {/* Added Arrow for showing the child node. Github issue #94 */}
                        {"gateway_connected" in device ? (
                          device.gateway_connected == true ? (
                            <span
                              style={{ fontSize: "35px", marginLeft: "45%" }}
                            >
                              &#x2937;
                            </span>
                          ) : device.device_type === 3 ? (
                            "4L"
                          ) : device.device_type === 2 ? (
                            "4M"
                          ) : device.device_type === 1 ? (
                            "4N"
                          ) : (
                            "4S"
                          )
                        ) : device.device_type === 3 ? (
                          "4L"
                        ) : device.device_type === 2 ? (
                          "4M"
                        ) : device.device_type === 1 ? (
                          "4N"
                        ) : (
                          "4S"
                        )}
                      </p>
                    </div>
                  </TableCell>
                  {/* Added a new column to show the child classes. Github issue #94 */}
                  <TableCell className={styles.tableCell}>
                    <div>
                      <p className={styles.device}>
                        {"gateway_connected" in device
                          ? device.gateway_connected == false
                            ? ""
                            : device.device_type === 2
                            ? "4M"
                            : device.device_type === 1
                            ? "4N"
                            : ""
                          : ""}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <div className={styles.flex}>
                      <p className={styles.device}>{device.device_id}</p>
                    </div>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <div className={styles.flex}>
                      <p>{device.device_name}</p>
                    </div>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <div
                      className={clsx(styles.flex)}
                      style={{
                        fontWeight: 600,
                        color: handleColor(
                          Number(device.connectivity_info.last_communication)
                        )
                      }}
                    >
                      {format(
                        fromUnixTime(
                          device.connectivity_info.last_communication
                        ),
                        // changed format from "MMMM dd,yyyy" to MM/dd/yyyy for web view issue #24
                        "MM/dd/yyyy | h:mm a"
                      )}
                    </div>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {displaySignalStrength(
                      device.connectivity_info.signal_strength
                    )}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {displayPower(device.connectivity_info.power_info)}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {displayDataUsed(device.connectivity_info.data_used)}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <div className={styles.flex}>
                      {getSubscriptionLabel(
                        device?.subscription_info?.subscription_level || 0
                      )}
                    </div>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <div
                      className={styles.flex}
                      style={{
                        color: handleDateColor(
                          device?.subscription_info?.subscription_expiry
                        ),
                        //calling function for text font weight as bold or normal for issue #119
                        fontWeight: handleFontWeight(
                          device?.subscription_info?.subscription_expiry
                        )
                      }}
                    >
                      {customDateFormatUnix(
                        device?.subscription_info?.subscription_expiry,
                        // changed format from "MMMM dd,yyyy" to MM/dd/yyyy for web view issue #24
                        "MM/dd/yyyy"
                      )}
                    </div>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <div
                      className={styles.flex}
                      onClick={event =>
                        handleElypsisClick(event, device.device_id)
                      }
                    >
                      <div style={{ display: "flex" }}>
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.5 3.75C7.5 2.92157 8.17157 2.25 9 2.25C9.82843 2.25 10.5 2.92157 10.5 3.75C10.5 4.57843 9.82843 5.25 9 5.25C8.17157 5.25 7.5 4.57843 7.5 3.75Z"
                            fill={theme.palette.primary.light}
                          />
                          <path
                            d="M7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9Z"
                            fill={theme.palette.primary.light}
                          />
                          <path
                            d="M9 12.75C8.17157 12.75 7.5 13.4216 7.5 14.25C7.5 15.0784 8.17157 15.75 9 15.75C9.82843 15.75 10.5 15.0784 10.5 14.25C10.5 13.4216 9.82843 12.75 9 12.75Z"
                            fill={theme.palette.primary.light}
                          />
                        </svg>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <DevicePopOver
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            deviceId={deviceId}
          />
        </TableContainer>
      )}

      <SuccessDeviceModal />
    </Grid>
  );
};

export default DevicesTable;
